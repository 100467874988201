import React from "react";
import Head from "next/head";
import Script from "next/script";
import { AxiomWebVitals } from "next-axiom";
import Announcements from "./announcements/announcements";

const Layout = (pageProps) => {
	return (
		<div className={pageProps.className}>
			<Head>
				<link
					rel="icon"
					type="image/png"
					href="/favicon-96x96.png"
					sizes="96x96"
				/>
				<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
				<link rel="shortcut icon" href="/favicon.ico" />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<meta name="apple-mobile-web-app-title" content="ASL" />
				<link rel="manifest" href="/site.webmanifest" />
				<link href={pageProps.canonical} rel="canonical" />
				<title>{pageProps.title}</title>
				<meta property="og:site_name" content="Sign Language 101" />
				<meta property="og:title" content={pageProps.ogTitle} />
				<meta property="og:type" content="website" />
				<meta property="og:description" content={pageProps.ogDescription} />
				<meta property="og:url" content={pageProps.ogUrl} />
				<meta
					property="og:image"
					content={
						pageProps.pageImage
							? pageProps.pageImage
							: "https://www.signlanguage101.com/img/home/multi_mockup3.png"
					}
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={pageProps.ogTitle} />
				<meta name="twitter:description" content={pageProps.ogDescription} />
				<meta name="twitter:site" content="@101sign" />
				<meta
					name="twitter:image"
					content={
						pageProps.pageImage
							? pageProps.pageImage
							: "https://www.signlanguage101.com/img/home/multi_mockup3.png"
					}
				/>
				<meta name="twitter:creator" content="@101sign" />
				<meta name="description" content={pageProps.description} />
				<meta name="keywords" content={pageProps.keywords} />
				<meta
					name="facebook-domain-verification"
					content="v4cp6j0wp7gafh4028m8occlrk5q7m"
				/>
				{pageProps.structuredData && (
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={pageProps.structuredData}
						key="product-jsonld"
					/>
				)}
			</Head>
			<AxiomWebVitals />
			<div>
				{process.env.NODE_ENV === "production" ? (
					<Script
						id="cookieyes-shopify"
						src="https://cdn-cookieyes.com/common/shopify.js"
					/>
				) : null}
				<Announcements announcements={[]} />
				{pageProps.children}
			</div>
		</div>
	);
};

export default Layout;
