import React, { useState, useEffect, lazy, Suspense } from "react";
import { Inter, Alexandria } from "next/font/google";
import { GoogleTagManager } from "@next/third-parties/google";
import "@/styles/globals.css";
import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";

import { CookiesProvider } from "react-cookie";
import CartContext from "@/context/CartContext";
import Script from "next/script";
import { fetchFeatures } from "@/utils/features";
import { FeatureProvider } from "@/context/FeatureContext";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { VisualEditing } from "@sanity/visual-editing/next-pages-router";

import Layout from "@/components/Layout";

import AnnouncementsContext from "@/context/AnnouncementsContext";
import { gtmPageView } from "@/utils/analytics";
import ShopifyAnalytics from "@/components/ShopifyAnalytics";
import CrossSellAnnouncementsContext from "@/context/CrossSellAnnouncementsContext";

const PreviewProvider = lazy(() => import("@/components/PreviewProvider"));

const inter = Inter({
	subsets: ["latin"],
	variable: "--font-inter",
});

const alexandria = Alexandria({
	subsets: ["latin"],
	variable: "--font-alexandria",
});

function MyApp({ Component, pageProps }) {
	const [queryClient] = React.useState(() => new QueryClient());
	const [enabledFeatures, setFeatures] = useState([]);
	const { draftMode, token } = pageProps;

	const processFeatures = async () => {
		const features = await fetchFeatures();

		setFeatures(features);
	};

	useEffect(() => {
		processFeatures();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const props = {
			page_title: pageProps.title || null,
		};
		gtmPageView(props);
	}, [pageProps]);

	return draftMode ? (
		<PreviewProvider token={token}>
			<ErrorBoundary>
				<CookiesProvider>
					<QueryClientProvider client={queryClient}>
						<Hydrate state={pageProps.dehydratedState}>
							<FeatureProvider>
								<CartContext>
									<AnnouncementsContext>
										<CrossSellAnnouncementsContext>
											<div
												className={`${inter.variable} font-sans ${alexandria.variable}`}
											>
												<Layout {...pageProps}>
													<Component {...pageProps} />
												</Layout>
											</div>
										</CrossSellAnnouncementsContext>
									</AnnouncementsContext>
								</CartContext>
							</FeatureProvider>
						</Hydrate>
					</QueryClientProvider>
				</CookiesProvider>
			</ErrorBoundary>
			<Suspense>
				<VisualEditing />
			</Suspense>
		</PreviewProvider>
	) : (
		<ErrorBoundary>
			<CookiesProvider>
				<QueryClientProvider client={queryClient}>
					<Hydrate state={pageProps.dehydratedState}>
						<FeatureProvider>
							<CartContext>
								<AnnouncementsContext>
									<CrossSellAnnouncementsContext>
										<div
											className={`${inter.variable} font-sans ${alexandria.variable}`}
										>
											<Layout {...pageProps}>
												<Component {...pageProps} />
												{process.env.NODE_ENV === "production" ? (
													<>
														<GoogleTagManager gtmId={"GTM-W7SHZC3"} />
														<Script
															src="https://betteruptime.com/widgets/announcement.js"
															data-id="141041"
															strategy="lazyOnload"
														></Script>
														<ShopifyAnalytics />
													</>
												) : null}
											</Layout>
										</div>
									</CrossSellAnnouncementsContext>
								</AnnouncementsContext>
							</CartContext>
						</FeatureProvider>
					</Hydrate>
				</QueryClientProvider>
			</CookiesProvider>
		</ErrorBoundary>
	);
}

export default MyApp;
