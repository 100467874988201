import { ArrowRight } from "lucide-react";
import useSlideShow from "@/context/use_slide_show";
import Announcement from "./announcement";
import { twMerge } from "tailwind-merge";
import { useAnnouncementsContext } from "@/context/AnnouncementsContext";

export default function Announcements() {
	const { announcements } = useAnnouncementsContext();

	const { currentIndex, goToNext, goToPrev } = useSlideShow(
		announcements ? announcements.length : 0,
		5000 // 2 second rotation interval
	);

	if (!announcements || (announcements && announcements.length == 0)) {
		return <></>;
	} else {
		return (
			<section
				className={twMerge(
					"bg-black text-white p-2.5 md:px-4",
					announcements && announcements.length > 1 ? "md:py-1.5" : "md:py-2"
				)}
			>
				<div
					className={twMerge(
						"flex gap-4 max-w-[56rem] mx-auto",
						announcements && announcements.length > 1
							? "justify-between"
							: "justify-center"
					)}
				>
					{announcements && announcements.length > 1 && (
						<button
							type="button"
							aria-controls="announcement-bar"
							aria-label="Previous"
							onClick={goToPrev}
							className="p-1"
						>
							<ArrowRight className="rotate-180 w-4 h-auto" />
						</button>
					)}

					<div
						id="announcement-bar"
						className="grid grid-cols-1 grid-rows-1 place-items-center"
					>
						{announcements &&
							announcements.map((announcement, index) => (
								<Announcement
									announcement={announcement}
									key={announcement._id}
									isVisible={currentIndex === index}
								/>
							))}
					</div>

					{announcements && announcements.length > 1 && (
						<button
							type="button"
							aria-controls="announcement-bar"
							aria-label="Next"
							onClick={goToNext}
							className="p-1"
						>
							<ArrowRight className="w-4 h-auto" />
						</button>
					)}
				</div>
			</section>
		);
	}
}
