// context/use_slide_show.js
import { useState, useEffect, useCallback } from "react";

export default function useSlideShow(length, autoRotateInterval = 2000) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isAutoRotating, setIsAutoRotating] = useState(true);

	const goToNext = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % length);
	}, [length]);

	const goToPrev = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + length) % length);
	}, [length]);

	// Reset auto-rotation when user manually navigates
	const handleManualNav = useCallback(
		(direction) => {
			setIsAutoRotating(false);
			if (direction === "next") {
				goToNext();
			} else {
				goToPrev();
			}
			// Restart auto-rotation after 5 seconds of inactivity
			setTimeout(() => setIsAutoRotating(true), 5000);
		},
		[goToNext, goToPrev]
	);

	useEffect(() => {
		let timer;
		if (length > 1 && isAutoRotating) {
			timer = setInterval(goToNext, autoRotateInterval);
		}
		return () => clearInterval(timer);
	}, [length, goToNext, isAutoRotating, autoRotateInterval]);

	return {
		currentIndex,
		goToNext: () => handleManualNav("next"),
		goToPrev: () => handleManualNav("prev"),
	};
}
