export const initialState = {
	cart: {
		id: null,
		checkoutUrl: "",
		cost: {
			totalAmount: {
				amount: "0",
			},
			subtotalAmount: {
				amount: "0",
			},
			totalTaxAmount: {
				amount: "0",
			},
		},
		discountCodes: [],
		discountAllocations: [],
		attributes: [],
		lines: [],
		totalQuantity: 0,
		buyerIdentity: { countryCode: "US" },
	},
	shopperIdentity: { currencyCode: "USD", countryCode: "US" },
	errors: [],
};

export const cartReducer = (state, action) => {
	switch (action.type) {
		case "CREATE_CART":
			return { ...state, cart: action.payload };
		case "UPDATE_SHOPPER_IDENTITY":
			return { ...state, shopperIdentity: action.payload };
		case "ADD_TO_CART":
			return { ...state, cart: [...state.cart, ...action.payload] };
		case "REMOVE_FROM_CART":
			return {
				...state,
				cart: state.cart.filter((c) => c.id !== action.payload.id),
			};
		case "CHANGE_CART_QTY":
			return {
				...state,
				cart: state.cart.filter((c) =>
					c.id === action.payload.id ? (c.qty = action.payload.qty) : c.qty
				),
			};
		case "REPLACE_CART":
			return { ...state, cart: action.payload };
		case "INIT_STORED":
			return { ...state, cart: action.payload };
		case "CLEAR_CART":
			return {
				...state,
				cart: initialState.cart,
			};
		case "ADD_ERROR":
			return { ...state, errors: action.payload };
		case "CLEAR_ERROR":
			return { ...state, errors: initialState.errors };
		default:
			return state;
	}
};
