import cartFragment from "../fragments/cart";

export const addToCartMutation = /* GraphQL */ `
	mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const createCartMutation = /* GraphQL */ `
	mutation createCart(
		$lineItems: [CartLineInput!]
		$buyerIdentityInput: CartBuyerIdentityInput!
		$cartAttributes: [AttributeInput!]!
		$discountCodes: [String!]
	) {
		cartCreate(
			input: {
				lines: $lineItems
				buyerIdentity: $buyerIdentityInput
				attributes: $cartAttributes
				discountCodes: $discountCodes
			}
		) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const editCartItemsMutation = /* GraphQL */ `
	mutation editCartItems($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const removeFromCartMutation = /* GraphQL */ `
	mutation removeFromCart($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const updateCartDiscountCodeMutation = /* GraphQL */ `
	mutation CartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
		cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;

export const updateCartBuyerIndentityMutation = /* GraphQL */ `
	mutation CartBuyerIdentityUpdate(
		$cartId: ID!
		$buyerIdentity: CartBuyerIdentityInput!
	) {
		cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
			cart {
				...cart
			}
		}
	}
	${cartFragment}
`;
