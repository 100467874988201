import axios from "axios";

export const fetchFeatures = async () => {
	try {
		const { data } = await axios.get("/api/features");

		return data;
	} catch (e) {
		console.error("Something went wrong");
	}

	return [];
};
