export const level1Reviews = [
	{
		author: "Patricia P.",
		content:
			"This course is EXACTLY what I was looking for!! It's structured like a classroom or online college course and it is perfect. The instructor is very easy to learn from and his signing is easy to follow.  Most defiantly a 5+ star rating!",
		stars: "",
	},
	{
		author: "Gaylene C.",
		content:
			"This is an excellent beginner ASL class.  Lots of vocabulary on different subjects and numbers.  Dr. Bridges offers language tips and information on Deaf culture, which is valuable for those wanting to learn ASL.",
		stars: "",
	},
	{
		author: "Rachel S.",
		content:
			"The course was easy to understand and allows you to move at your own pace. Very User Friendly.",
		stars: "",
	},
	{
		author: "Radames V.",
		content:
			"This course has really helped give me the confidence to pursue my journey into language learning as a whole.",
		stars: "",
	},
	{
		author: "Jaylin J.",
		content:
			"This course was provided by my class/school. I'm happy they switched over to this platform, as it seems to be far more up to date than the last program they were using. Keep up the amazing work, everyone involved with making this/these course(s) a reality.",
		stars: "",
	},
	{
		author: "Joan C.",
		content:
			"I highly recommend this course to give basic insight into the deaf community and learn sign language skills. ",
		stars: "",
	},
	{
		author: "Antonio A.",
		content:
			"It was very easy to follow along! I enjoyed being able to pause and rewind parts of the lesson – just in case I missed a sign. I really recommend this course!",
		stars: "",
	},
	{
		author: "Student",
		content:
			"I absolutely loved the course! I do feel like I could successfully interact with a deaf person which is so important because I love to make others feel comfortable with their native language, so being able to communicate with Deaf people in their language is something I have always wanted, and something this course definitely delivered!",
		stars: "",
	},
	{
		author: "Student",
		content:
			"This course taught me to read and sign at a beginner level, it was an amazing experience for the price!",
		stars: "",
	},
	{
		author: "Carla C.",
		content:
			"Dr. Bridges is a great teacher. The repetition really helps. Seeing the signs also from the side really helps. I'm definitely going to take more courses.",
		stars: "",
	},
	{
		author: "Sydni L.",
		content: "I love Dr. Byron’s energy and ease of understanding!",
		stars: "",
	},
	{
		author: "Student",
		content:
			"This is a great introductory course to ASL in that you'll walk away with a basic understanding of the language and a basic vocabulary of everyday words in a relatively short period of time.",
		stars: "",
	},
];

export const level2Reviews = [
	{
		author: "Jaylin J.",
		content:
			"This course was provided by my class/school. I'm happy they switched over to this platform, as it seems to be far more up to date than the last program they were using. Keep up the amazing work, everyone involved with making this/these course(s) a reality.",
		stars: "",
	},
	{
		author: "Edward H.",
		content:
			"The finger-spelling and receptive practice helped me feel comfortable signing with a Deaf person. I got to practice watching Dr. Byron again and again until I knew what he exactly was signing!",
	},
	{
		author: "Lilly B.",
		content:
			"This is the most comprehensive online ASL course I've seen! Dr. Bridges makes it so much fun to learn.",
	},
	{
		author: "Adam R.",
		content:
			"It’s regrettable that I took so long to start learning sign language. I feel like I have a great foundation and vocabulary, and soon I will begin signing with my friends that know ASL.",
	},
];
