import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCartContext } from "@/context/CartContext";
import { Button } from "../common/Button";
import CartItem from "./CartItem";

export default function AddedItemToCartBanner() {
	const { cart, lastItemAddedToCart, setLastItemAddedToCart } =
		useCartContext();

	return (
		<Dialog
			as="div"
			// onClose={() => setCartOpen?.(false) || (() => {})}
			role="dialog"
			aria-modal="true"
			aria-labelledby="modalTitle"
			aria-describedby="modalDescription"
			// open={cartOpen ?? false}
			open={!!lastItemAddedToCart}
			onClose={() => setLastItemAddedToCart(null)}
			transition
			className=" fixed inset-0 flex w-screen items-center justify-center  p-4 transition duration-500 ease-in-out data-[closed]:opacity-0 data-[closed]:-translate-y-full"
		>
			<DialogPanel className=" font-modals absolute top-0 lg:top-6 lg:right-6 pointer-events-auto w-screen lg:max-w-md px-0">
				<div className="flex lg:mx-auto  flex-col overflow-y-scroll rounded-b-xl lg:rounded-xl bg-white shadow-xl">
					<div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
						<div className="flex items-start justify-between">
							<DialogTitle
								id="modalTitle"
								className="text-lg font-semibold text-gray-900"
							>
								Added to cart
							</DialogTitle>
							<p id="modalDescription" className="sr-only">
								The item has been successfully added to your cart. You can
								proceed to view your cart or continue shopping.
							</p>
							<div className="ml-3 flex h-7 items-center">
								<button
									type="button"
									className="-m-2 p-2 text-gray-400 hover:text-gray-500"
									onClick={() => setLastItemAddedToCart(null)}
								>
									<span className="sr-only">Close panel</span>
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
						</div>

						<div className="mt-8">
							<div className="flow-root">
								{lastItemAddedToCart && lastItemAddedToCart?.merchandise ? (
									<ul className="-my-6 divide-y divide-gray-100">
										<CartItem item={lastItemAddedToCart} />
									</ul>
								) : (
									<div className="text-center">
										<div className="mt-8 mb-2 font-semibold text-lg">
											Item has been added to cart!
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					{/* Screen reader announcement for item added to cart */}
					<div aria-live="assertive" className="sr-only">
						Item added to cart.
					</div>

					<div className="pb-6 pt-2 px-4 sm:px-6">
						<div className="mt-6 flex flex-row space-x-4">
							<Button
								variant="outline"
								color="slate"
								href="/cart"
								className="py-2"
								onClick={() => setLastItemAddedToCart(null)}
							>
								View Cart ({cart.totalQuantity})
							</Button>
							{/* <Button
													variant="solid"
													color="yellow"
													href="/cart"
													className="py-2"
													onClick={(e) => setCartOpen(false)}
												>
													Checkout
												</Button> */}
						</div>
						<div className="mt-6 flex justify-center text-center text-sm text-gray-500">
							<p>
								or{" "}
								<button
									type="button"
									className="font-medium text-aquamarine-blue-900 hover:text-aquamarine-blue-800"
									onClick={() => setLastItemAddedToCart(null)}
								>
									Continue Shopping
									<span aria-hidden="true"> &rarr;</span>
								</button>
							</p>
						</div>
					</div>
				</div>
			</DialogPanel>
		</Dialog>
	);
}
