import {
	HIDDEN_PRODUCT_TAG,
	SHOPIFY_GRAPHQL_API_ENDPOINT,
	TAGS,
} from "@/lib/shopify/constants";
import { isShopifyError } from "@/lib/shopify/type-guards";
import {
	addToCartMutation,
	createCartMutation,
	editCartItemsMutation,
	removeFromCartMutation,
	updateCartDiscountCodeMutation,
	updateCartBuyerIndentityMutation,
} from "./mutations/cart";
import { getCartQuery } from "./queries/cart";
import {
	getCollectionProductsQuery,
	getCollectionByVariantQuery,
	getCollectionQuery,
	getCollectionsQuery,
} from "./queries/collection";
import { getMenuQuery } from "./queries/menu";
import { getPageQuery, getPagesQuery } from "./queries/page";
import {
	getProductQuery,
	getProductVariantQuery,
	getProductRecommendationsQuery,
	getProductsQuery,
	getProductById,
	getProductVariantPriceQuery,
} from "./queries/product";
import { courses } from "@/data/courses";
import { books } from "@/data/books";

const domain = `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}`;
const endpoint = `${domain}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;
const key = process.env.NEXT_PUBLIC_SHOPIFY_PUBLIC_ACCESS_TOKEN;

export async function shopifyFetch({
	cache = "force-cache",
	headers,
	query,
	tags,
	variables,
}) {
	try {
		const result = await fetch(endpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Shopify-Storefront-Access-Token": key,
				...headers,
			},
			body: JSON.stringify({
				...(query && { query }),
				...(variables && { variables }),
			}),
			cache,
			...(tags && { next: { tags } }),
		});

		const body = await result.json();

		if (body.errors) {
			throw body.errors[0];
		}

		return {
			status: result.status,
			body,
		};
	} catch (e) {
		if (isShopifyError(e)) {
			throw {
				status: e.status || 500,
				message: e.message,
				query,
			};
		}

		throw {
			error: e,
			query,
		};
	}
}

export const removeEdgesAndNodes = (array) => {
	return array.edges.map((edge) => edge?.node);
};

const reshapeCart = (cart) => {
	if (!cart.cost?.totalTaxAmount) {
		cart.cost.totalTaxAmount = {
			amount: "0.0",
			currencyCode: "USD",
		};
	}

	return {
		...cart,
		lines: removeEdgesAndNodes(cart.lines),
	};
};

const reshapeCollection = (collection) => {
	if (!collection) {
		return undefined;
	}

	return {
		...collection,
		path: `/search/${collection.handle}`,
	};
};

const reshapeCollections = (collections) => {
	const reshapedCollections = [];

	for (const collection of collections) {
		if (collection) {
			const reshapedCollection = reshapeCollection(collection);

			if (reshapedCollection) {
				reshapedCollections.push(reshapedCollection);
			}
		}
	}

	return reshapedCollections;
};

export const reshapeProduct = (product, filterHiddenProducts = true) => {
	if (
		!product ||
		(filterHiddenProducts && product.tags.includes(HIDDEN_PRODUCT_TAG))
	) {
		return undefined;
	}

	const { images, variants, collections, ...rest } = product;

	return {
		...rest,
		images: removeEdgesAndNodes(images),
		variants: removeEdgesAndNodes(variants),
		collections: removeEdgesAndNodes(collections),
	};
};

export const reshapeProducts = (products) => {
	const reshapedProducts = [];

	for (const product of products) {
		if (product) {
			const reshapedProduct = reshapeProduct(product);

			if (reshapedProduct) {
				reshapedProducts.push(reshapedProduct);
			}
		}
	}

	return reshapedProducts;
};

export async function createCart({
	lineItems,
	buyerIdentityInput,
	cartAttributes,
	discountCodes = [],
}) {
	const res = await shopifyFetch({
		query: createCartMutation,
		variables: {
			lineItems,
			buyerIdentityInput,
			cartAttributes,
			discountCodes,
		},
		cache: "no-store",
	});

	return reshapeCart(res.body.data.cartCreate.cart);
}

export async function addToCart({ cartId, lines }) {
	const res = await shopifyFetch({
		query: addToCartMutation,
		variables: {
			cartId,
			lines,
		},
		cache: "no-store",
	});
	return reshapeCart(res.body.data.cartLinesAdd.cart);
}

export async function removeFromCart({ cartId, lineIds }) {
	const res = await shopifyFetch({
		query: removeFromCartMutation,
		variables: {
			cartId,
			lineIds,
		},
		cache: "no-store",
	});

	return reshapeCart(res.body.data.cartLinesRemove.cart);
}

export async function updateCart({ cartId, lines }) {
	const res = await shopifyFetch({
		query: editCartItemsMutation,
		variables: {
			cartId,
			lines,
		},
		cache: "no-store",
	});

	return reshapeCart(res.body.data.cartLinesUpdate.cart);
}

export async function updateCartDiscountCodes({ cartId, discountCodes }) {
	const res = await shopifyFetch({
		query: updateCartDiscountCodeMutation,
		variables: {
			cartId,
			discountCodes,
		},
		cache: "no-store",
	});

	return reshapeCart(res.body.data.cartDiscountCodesUpdate.cart);
}

export async function updateCartBuyerIdentity({ cartId, buyerIdentity }) {
	const res = await shopifyFetch({
		query: updateCartBuyerIndentityMutation,
		variables: {
			cartId,
			buyerIdentity,
		},
		cache: "no-store",
	});

	return reshapeCart(res.body.data.cartBuyerIdentityUpdate.cart);
}

export async function getCart(cartId) {
	const res = await shopifyFetch({
		query: getCartQuery,
		variables: { cartId },
		cache: "no-store",
	});

	if (!res.body.data.cart) {
		return null;
	}

	return reshapeCart(res.body.data.cart);
}

export async function getRawCart(cartId) {
	const res = await shopifyFetch({
		query: getCartQuery,
		variables: { cartId },
		cache: "no-store",
	});

	if (!res.body.data.cart) {
		return null;
	}

	return res.body.data.cart;
}

export async function getCollection(handle) {
	const res = await shopifyFetch({
		query: getCollectionQuery,
		tags: [TAGS.collections],
		variables: {
			handle,
		},
	});

	return reshapeCollection(res.body.data.collection);
}

export async function getCollectionProducts({
	collection,
	reverse,
	sortKey,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getCollectionProductsQuery,
		tags: [TAGS.collections, TAGS.products],
		variables: {
			handle: collection,
			reverse,
			sortKey,
			countryCode,
		},
	});

	if (!res.body.data.collection) {
		console.log(`No collection found for \`${collection}\``);
		return [];
	}

	return reshapeProducts(
		removeEdgesAndNodes(res.body.data.collection.products)
	);
}

export async function getCollectionByVariant({
	collection,
	reverse,
	sortKey,
	variantName,
	variantValue,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getCollectionByVariantQuery,
		tags: [TAGS.collections, TAGS.products],
		variables: {
			handle: collection,
			reverse,
			sortKey,
			variantName,
			variantValue,
			countryCode,
		},
	});

	if (!res.body.data.collection) {
		console.log(`No collection found for \`${collection}\``);
		return [];
	}

	const reshapedProducts = reshapeProducts(
		removeEdgesAndNodes(res.body.data.collection.products)
	);
	if (collection === "active-courses") {
		reshapedProducts.forEach((product) => {
			if (courses.find((o) => o.handle === product.handle)) {
				product.productDetails = courses.find(
					(o) => o.handle === product.handle
				);
			} else product.productDetails = null;
		});
	}

	return reshapedProducts;
}

export async function getCollections() {
	const res = await shopifyFetch({
		query: getCollectionsQuery,
		tags: [TAGS.collections],
	});
	const shopifyCollections = removeEdgesAndNodes(res.body?.data?.collections);
	const collections = [
		{
			handle: "",
			title: "All",
			description: "All products",
			seo: {
				title: "All",
				description: "All products",
			},
			path: "/search",
			updatedAt: new Date().toISOString(),
		},
		// Filter out the `hidden` collections.
		// Collections that start with `hidden-*` need to be hidden on the search page.
		...reshapeCollections(shopifyCollections).filter(
			(collection) => !collection.handle.startsWith("hidden")
		),
	];

	return collections;
}

export async function getMenu(handle) {
	const res = await shopifyFetch({
		query: getMenuQuery,
		tags: [TAGS.collections],
		variables: {
			handle,
		},
	});

	return (
		res.body?.data?.menu?.items.map((item) => ({
			title: item.title,
			path: item.url
				.replace(domain, "")
				.replace("/collections", "/search")
				.replace("/pages", ""),
		})) || []
	);
}

export async function getPage(handle) {
	const res = await shopifyFetch({
		query: getPageQuery,
		variables: { handle },
	});

	return res.body.data.pageByHandle;
}

export async function getPages() {
	const res = await shopifyFetch({
		query: getPagesQuery,
	});

	return removeEdgesAndNodes(res.body.data.pages);
}

export async function getProduct(handle) {
	const res = await shopifyFetch({
		query: getProductQuery,
		tags: [TAGS.products],
		variables: {
			handle,
		},
	});

	return reshapeProduct(res.body.data.product, false);
}

export async function getProductByID(id) {
	const res = await shopifyFetch({
		query: getProductById,
		tags: [TAGS.products],
		variables: {
			id,
		},
	});

	return reshapeProduct(res.body.data.product, false);
}

export async function getProductVariantPrice({
	handle,
	variantName,
	variantValue,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getProductVariantPriceQuery,
		tags: [TAGS.products],
		variables: {
			handle,
			variantName,
			variantValue,
			countryCode,
		},
	});

	if (res.body.data?.product) {
		return res.body.data.product;
		//return reshapeProduct(res.body.data.product, false);
	} else {
		return null;
	}
}

export async function getProductByVariant({
	handle,
	variantName,
	variantValue,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getProductVariantQuery,
		tags: [TAGS.products],
		variables: {
			handle,
			variantName,
			variantValue,
			countryCode,
		},
	});

	if (res.body.data?.product) {
		let reshapedProduct = reshapeProduct(res.body.data.product, false);

		if (reshapedProduct.productType === "Course") {
			if (courses.find((o) => o.handle === handle)) {
				reshapedProduct.productDetails = courses.find(
					(o) => o.handle === handle
				);
			} else reshapedProduct.productDetails = null;
		} else if (reshapedProduct.productType === "Book") {
			if (books.find((o) => o.handle === handle)) {
				reshapedProduct.productDetails = books.find((o) => o.handle === handle);
			} else reshapedProduct.productDetails = null;
		}

		return reshapedProduct;
	} else {
		let localData = { productDetails: null };
		if (courses.find((o) => o.handle === handle)) {
			localData.productDetails = courses.find((o) => o.handle === handle);
		} else if (books.find((o) => o.handle === handle)) {
			localData.productDetails = books.find((o) => o.handle === handle);
		} else localData.productDetails = null;
		return localData;
	}
}

export async function getProductRecommendations({
	productId,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getProductRecommendationsQuery,
		tags: [TAGS.products],
		variables: {
			productId,
			countryCode,
		},
	});

	//return reshapeProducts(res.body.data.productRecommendations);

	const reshapedProducts = reshapeProducts(
		res.body.data.productRecommendations
	);

	reshapedProducts.forEach((product) => {
		if (product.productType === "Course") {
			if (courses.find((o) => o.handle === product.handle)) {
				product.productDetails = courses.find(
					(o) => o.handle === product.handle
				);
			} else product.productDetails = null;
		} else if (
			product.productType === "Book" ||
			product.productType === "BookBundle"
		) {
			if (books.find((o) => o.handle === product.handle)) {
				product.productDetails = books.find((o) => o.handle === product.handle);
			} else product.productDetails = null;
		}
	});

	return reshapedProducts;
}

export async function getProducts({
	query,
	reverse,
	sortKey,
	countryCode = "US",
}) {
	const res = await shopifyFetch({
		query: getProductsQuery,
		tags: [TAGS.products],
		variables: {
			query,
			reverse,
			sortKey,
			countryCode,
		},
	});

	return reshapeProducts(removeEdgesAndNodes(res.body.data.products));
}
