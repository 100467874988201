import productFragment from "../fragments/product";
import productVariantFragment from "../fragments/productVariant";
import seoFragment from "../fragments/seo";

const collectionFragment = /* GraphQL */ `
	fragment collection on Collection {
		handle
		title
		description
		seo {
			...seo
		}
		updatedAt
	}
	${seoFragment}
`;

export const getCollectionQuery = /* GraphQL */ `
	query getCollection($handle: String!) {
		collection(handle: $handle) {
			...collection
		}
	}
	${collectionFragment}
`;

export const getCollectionsQuery = /* GraphQL */ `
	query getCollections {
		collections(first: 100, sortKey: TITLE) {
			edges {
				node {
					...collection
				}
			}
		}
	}
	${collectionFragment}
`;

export const getCollectionProductsQuery = /* GraphQL */ `
	query getCollectionProducts(
		$handle: String!
		$sortKey: ProductCollectionSortKeys
		$reverse: Boolean
		$countryCode: CountryCode!
	) @inContext(country: $countryCode) {
		collection(handle: $handle) {
			products(sortKey: $sortKey, reverse: $reverse, first: 100) {
				edges {
					node {
						...product
					}
				}
			}
		}
	}
	${productFragment}
`;

export const getCollectionByVariantQuery = /* GraphQL */ `
	query getCollectionProducts(
		$handle: String!
		$sortKey: ProductCollectionSortKeys
		$reverse: Boolean
		$variantName: String!
		$variantValue: String!
		$countryCode: CountryCode!
	) @inContext(country: $countryCode) {
		collection(handle: $handle) {
			products(sortKey: $sortKey, reverse: $reverse, first: 100) {
				edges {
					node {
						...product
					}
				}
			}
		}
	}
	${productVariantFragment}
`;
