import productFragment from "./product";

const cartFragment = /* GraphQL */ `
	fragment cart on Cart {
		id
		checkoutUrl
		cost {
			subtotalAmount {
				amount
				currencyCode
			}
			totalAmount {
				amount
				currencyCode
			}
			totalTaxAmount {
				amount
				currencyCode
			}
		}
		buyerIdentity {
			countryCode
		}
		discountCodes {
			code
			applicable
		}
		discountAllocations {
			discountedAmount {
				amount
			}
		}
		attributes {
			key
			value
		}
		lines(first: 100) {
			edges {
				node {
					id
					quantity
					cost {
						totalAmount {
							amount
							currencyCode
						}
						subtotalAmount {
							amount
							currencyCode
						}
					}
					discountAllocations {
						discountedAmount {
							amount
							currencyCode
						}
					}
					attributes {
						key
						value
					}
					merchandise {
						... on ProductVariant {
							id
							title
							sku
							selectedOptions {
								name
								value
							}
							image {
								url
								height
								width
								altText
							}
							product {
								...product
							}
							price {
								amount
								currencyCode
							}
						}
					}
				}
			}
		}
		totalQuantity
	}
	${productFragment}
`;

export default cartFragment;
