const productVariantPriceFragment = /* GraphQL */ `
	fragment product on Product {
		id
		handle
		availableForSale
		title
		vendor
		productType
		collections(first: 10) {
			edges {
				node {
					title
				}
			}
		}
		variantBySelectedOptions(
			selectedOptions: [{ name: $variantName, value: $variantValue }]
		) {
			id
			title
			availableForSale
			compareAtPrice {
				amount
				currencyCode
			}
			price {
				amount
				currencyCode
			}
			sku
			image {
				url
			}
		}
	}
`;

export default productVariantPriceFragment;
