import { useState, useEffect } from "react";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { Button } from "@/components/common/Button";
import { useLogger } from "next-axiom";
import { AnimatePresence, motion } from "framer-motion";

const UserGoalsForm = ({ onComplete, sid }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({ defaultValues: { obstacle: "starting" } });

	const [subscriberError, setSubscriberError] = useState("");
	const [loading, setLoading] = useState(false);
	const log = useLogger();

	const handleSubscribe = (data) => {
		setSubscriberError("");
		setLoading(true);

		axios
			.post("/api/marketing/03-2024/learning-concerns", {
				id: sid,
				concern: data.obstacle,
			})
			.then((response) => {
				setLoading(false);
				onComplete();
			})
			.catch((err) => {
				setLoading(false);
				setSubscriberError(
					"Sorry, we're having trouble with our form. Please email us at hello@support.signlanguage101.com for help."
				);
			});
	};

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: 300, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: -300, opacity: 0 }}
			>
				<form
					onSubmit={handleSubmit(handleSubscribe)}
					method="POST"
					className="flex flex-col h-full justify-between  flex-wrap "
					style={{ minHeight: "inherit" }}
				>
					<div className="flex w-full flex-col">
						<p className="font-heading font-semibold text-gray-900  mb-2">
							One more question...
						</p>
						<p className="font-medium mb-4">
							What&apos;s your biggest challenge in learning ASL?
						</p>
						<div className="flex w-full flex-col gap-y-3 gap-x-2 mb-2">
							<div className="flex flex-row w-full ">
								<input
									type="radio"
									value="starting"
									name="obstacle-start"
									id="obstacle-start"
									{...register("obstacle")}
									className="  placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
								/>
								<label
									htmlFor="obstacle-start"
									className="block text-sm  ml-1.5 "
								>
									I don&apos;t know where to start
								</label>
							</div>
							<div className="flex flex-row w-full ">
								<input
									type="radio"
									value="time"
									name="obstacle-time"
									id="obstacle-time"
									{...register("obstacle")}
									className="  placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
								/>
								<label
									htmlFor="obstacle-time"
									className="block text-sm  ml-1.5 "
								>
									I don&apos;t have enough time
								</label>
							</div>
							<div className="flex flex-row w-full ">
								<input
									type="radio"
									value="others"
									name="obstacle-others"
									id="obstacle-others"
									{...register("obstacle")}
									className="  placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
								/>
								<label
									htmlFor="obstacle-others"
									className="block text-sm  ml-1.5 "
								>
									I don&apos;t know if I can learn on my own
								</label>
							</div>
							<div className="flex flex-row w-full ">
								<input
									type="radio"
									value="cost"
									name="obstacle-afford"
									id="obstacle-afford"
									{...register("obstacle")}
									className="  placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
								/>
								<label
									htmlFor="obstacle-afford"
									className="block text-sm  ml-1.5 "
								>
									I can&apos;t afford ASL classes
								</label>
							</div>
							<div className="flex flex-row w-full ">
								<input
									type="radio"
									value="none"
									name="obstacle-none"
									id="obstacle-none"
									{...register("obstacle")}
									className="  placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
								/>
								<label
									htmlFor="obstacle-none"
									className="block text-sm  ml-1.5 "
								>
									I don&apos;t have any challenges
								</label>
							</div>
						</div>
					</div>
					<div className="flex w-full justify-end  p-1.5">
						<Button
							variant="solid"
							color="blue"
							type="submit"
							className="lg:w-1/3 px-2 py-2"
							spinner={loading}
						>
							Complete
						</Button>
					</div>

					{subscriberError && (
						<p className="text-red-500 text-sm text-center w-full mt-4">
							{subscriberError}
						</p>
					)}
				</form>
			</motion.div>
		</AnimatePresence>
	);
};

export default UserGoalsForm;
