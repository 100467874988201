import { useState, useEffect, createContext } from "react";
import { fetchFeatures } from "@/utils/features";

const FeatureContext = createContext({
	// TypeScript will have hard time to determine its type,
	// if we don't cast this array to an array of strings.
	// Likely, we will end up with an array of never or any.
	enabledFeatures: [],
});

function FeatureProvider({ children, enabledFeatures }) {
	return (
		<FeatureContext.Provider value={enabledFeatures}>
			{children}
		</FeatureContext.Provider>
	);
}

function useFeatures() {
	// we need to read values defined in the FeatureToggleContext.
	// In this case, we'll take only the array of enabled features.
	//const { enabledFeatures } = React.useContext(FeatureContext);
	const [enabledFeatures, setFeatures] = useState([]);

	const processFeatures = async () => {
		const features = await fetchFeatures();

		setFeatures(features);
	};

	useEffect(() => {
		processFeatures();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const isEnabled = (featureName) => {
		return enabledFeatures.includes(featureName);
	};
	// if (context === undefined) {
	// 	throw new Error("useFeatures must be used within a FeatureProvider");
	// }
	// For consistency, We return an array of helpers,
	// so we follow the pattern defined by the useState hook.
	// It makes the code open for extensions,
	// so no need to refactor the app when a new helper is added here.
	return [isEnabled];
}

export { FeatureProvider, useFeatures };
