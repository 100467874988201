import { level1Reviews, level2Reviews } from "./courseReviews";
import deepDive from "@/components/images/screenshots/facial_expressions_deep_dive.png";

const byronDetails = {
	name: "Dr. Byron Bridges",
	thumbnailImage: "/img/course/byron-avatar.jpg",
	image: "/img/course/Byron-Ready-Circle-blue.png",
	about: [
		"Born deaf to deaf parents and growing up with a deaf sister, Dr. Byron W. Bridges has used ASL as the primary language throughout his whole life. He attended deaf schools and public schools. Dr. Bridges earned his Bachelors of Arts degree in Deaf Studies at California State University of Northridge, Masters of Arts (MA) degree in Linguistics from Gallaudet University, and a doctoral degree (Ed.D) in Deaf Studies and Deaf Education at Lamar University.",
		"Dr. Bridges co-authored the book, Deaf Tend Yours. He has written video scripts and produced numerous sign language related videotapes/DVDs. He is a nationally-known teacher and lecturer in ASL and sign language interpretation. Dr. Bridges has been in the field of ASL linguistics, interpreting and post secondary teaching of ASL as a second language for over 30 years. He is a certified Deaf interpreter (CDI), earning the certification through the Registry of Interpreters for the Deaf.",
		"Dr. Bridges is ready to teach you how to sign. Join the new classroom and learn how to sign at your own pace. Learn from an ASL authority who has successfully taught thousands of sign language students and interpreters. Sign Language 101 will have you signing today!",
	],
	shortBio:
		"Deaf, nationally known instructor and author in ASL and sign language interpretation.",
};

const sandraDetails = {
	name: "Sandra Mae Frank",
	thumbnailImage: "/img/course/SandraMFrank-9.png",
	image: "/img/course/about-your-instructor.png",
	about: [
		"Let us introduce you to your wonderful ASL teacher, Sandra Mae Frank!",
		"Sandra is an actress and producer who lost her hearing at the age of three. She then learned American Sign Language (ASL) and later became very passionate about acting. Sandra performed in plays during high school and studied Theatre Arts at Gallaudet University in Washington D.C. In 2015, Sandra made her Broadway debut as Wendla Bergmann in the revival of “Spring Awakening,” produced by the Deaf West Theatre. These experiences led her to acting roles in multiple films and television shows such as NBC's medical drama New Amsterdam. You also might have seen her on Super Bowl LVI, performing the National Anthem and America the Beautiful on live TV using American Sign Language. Pretty cool!!",
		"In this course, Sandra introduces the basic fundamentals of ASL in a structured and exciting format. Her teaching style is lively and engaging which makes learning fun and easy. Sandra's signing is not only beautiful but very clear for kids and beginning signers.",
		"Come join Sandra and learn the most beautiful language you've ever seen!",
	],
	shortBio: "Deaf actress and producer, known for her work on Broadway and TV.",
};

export const courses = [
	{
		id: 8525315408032,
		thinkificId: 2091207,
		title: "Getting Started with ASL",
		subTitle: "Level 1A",
		orderInSeries: 1,
		slug: "level-1a-online-asl-course",
		handle: "level-1a-online-asl-course",
		syllabusUrl: "/files/Level_1_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "beginner",
		type: "course",
		instructor: byronDetails,
		meta: {
			title: "Getting Started with ASL | Beginner Online Sign Language Course",
			description:
				"Perfect for ASL beginners! Learn 500+ essential signs and basic grammar with clear video lessons. Includes practice exercises and cultural insights.",
			keywords:
				"Level 1A ASL course, beginner sign language course, online ASL certification, earn CEUs in ASL, American Sign Language basics",
		},
		giftMeta: {
			title: "Gift Getting Started with ASL | Perfect Birthday Present",
			description:
				"Give a unique learning experience! This beginner ASL course makes a thoughtful birthday or holiday gift. Includes instant delivery and personal message.",
			keywords:
				"Level 1A ASL course, beginner sign language course, online ASL certification, earn CEUs in ASL, American Sign Language basics",
		},
		image: {
			url: "/img/course/level-1a-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Level 1 course is the first in our series that introduces you to your first signs and Deaf culture. Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture.",
		overview:
			"This course will provide students with an introduction to American Sign Language. It is designed for those who are new to sign language and want to learn the basics. Upon completion of this course, you will be familiar with common gestures and signs, fingerspelling, and Deaf culture.",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Have basic conversations in ASL within weeks",
				"Learn 500+ essential signs you'll use every day",
				"Practice real-world conversations at your own pace",
				"Build confidence through guided practice with Dr. Byron",
				"Track your progress with interactive quizzes",
				"Earn a certificate to celebrate your achievement",
				"Learn on the go or at home",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/byron_header_1a.png",
				altText: "Dr. Byron Bridges",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "2.5+ hours",
			ceuCount: "1.5",
			access: "Full course access for 1 year!",
		},
		topics: {
			image: {
				url: "/img/home/signing-2.jpg",
				altText: "Man learning sign language online",
				width: 800,
				height: 534,
			},
			list: [
				"Facial expressions & body language",
				"Numbers 1 - 50",
				"The ABCs",
				"Colors",
				"Pronouns",
				"Everyday Signs",
				"Common Phrases",
				"Antonyms",
				"Around the House",
				"Hobbies",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/level-1-mock.jpg",
				altText:
					"learn sign language online using any internet connected laptop, desktop, smartphone or tablet",
				width: 1157,
				height: 693,
			},
			text: [
				"Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. Throughout the course, Dr. Bridges will be your coach, mentor, and #1 cheerleader!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each lesson introduces a new topic you'll be exploring.  Pause, rewind, or change the video speed. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860476508,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail: "/img/course/previews/level-1a/intro-thumbnail.png",
				},
			},
			{
				title: "Deep Dive Topics",
				description:
					"Following the topic introduction, you'll delve into deeper into key topics related to ASL sentence structure and signing. Our interactive videos ensure that you not only understand the signs but also internalize them, enabling you to communicate expressively.",
				image: {
					src: deepDive,
					altText:
						"Level 1A - Getting Started with ASL: Deep Dive Topics in LMS",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance your receptive skills through interpreting signed questions and forming mental responses in ASL.",
				video: {
					id: 860452900,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-1a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860467560,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: level1Reviews,
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>No. As this is the first course in our series we teach you ASL from the beginning.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		prerequisites: [],
	},
	{
		id: 8525379502240,
		thinkificId: 2091210,
		title: "Building on the Basics of ASL",
		subTitle: "Level 1B",
		orderInSeries: 2,
		slug: "level-1b-online-asl-course",
		handle: "level-1b-online-asl-course",
		syllabusUrl: "/files/Level_1_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "beginner",
		type: "course",
		instructor: byronDetails,
		meta: {
			title: "Building on the Basics of ASL | Learn Sign Language Online",
			description:
				"Ready for more ASL? Master 500+ new signs and advance your grammar skills. Build on Level 1A with engaging video lessons and interactive practice.",
			keywords:
				"Level 1B ASL course, intermediate sign language course, online ASL lessons, earn CEUs ASL, American Sign Language practice",
		},
		giftMeta: {
			title: "Gift Building on the Basics of ASL | Unique Present for All",
			description:
				"Give a unique learning experience! This beginner ASL course makes a thoughtful birthday or holiday gift. Includes instant delivery and personal message.",
			keywords:
				"Level 1B ASL course, intermediate sign language course, online ASL lessons, earn CEUs ASL, American Sign Language practice",
		},
		image: {
			url: "/img/course/level-1b-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Level 1 course is the first in our series that introduces you to your first signs and Deaf culture. Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture.",
		overview:
			"This course will provide students with an introduction to American Sign Language. It is designed for those who are new to sign language and want to learn the basics. Upon completion of this course, you will be familiar with common gestures and signs, fingerspelling, and Deaf culture.",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Hold natural back-and-forth conversations in ASL",
				"Express your thoughts in complete ASL sentences",
				"Master 500+ new signs for everyday situations",
				"Practice real conversations with guided exercises",
				"Build fluency through interactive activities",
				"Earn a certificate showing your advancement",
				"Learn on the go or at home",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/byron_header_1b.png",
				altText: "Dr. Byron Bridges",
				width: 590,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "2.5+ hours",
			ceuCount: "1.5",
			access: "Full course access for 1 year!",
		},
		topics: {
			image: {
				url: "/img/home/signing-2.jpg",
				altText: "Man learning sign language online",
				width: 800,
				height: 534,
			},
			list: [
				"People",
				"Numbers 51 - 100",
				"The Body",
				"Clothing",
				"Time & Calendar",
				"Verbs",
				"Directions",
				"Food & Drinks",
				"Animals",
				"Sports",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/level-1-mock.jpg",
				altText:
					"learn sign language online using any internet connected laptop, desktop, smartphone or tablet",
				width: 1157,
				height: 693,
			},
			text: [
				"Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. Throughout the course, Dr. Bridges will be your coach, mentor, and #1 cheerleader!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic you'll be exploring. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860476508,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail: "/img/course/previews/level-1a/intro-thumbnail.png",
				},
			},
			{
				title: "Deep Dive Topics",
				description:
					"Following the topic introduction, you'll delve into deeper into key topics related to ASL sentence structure and signing. Our interactive videos ensure that you not only understand the signs but also internalize them, enabling you to communicate expressively.",
				image: {
					src: deepDive,
					altText:
						"Level 1A - Getting Started with ASL: Deep Dive Topics in LMS",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance receptive skills through interpreting signed questions and forming mental responses in ASL.",
				video: {
					id: 860452900,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-1a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next topic. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860467560,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: level1Reviews,
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>Yes.  As all of our courses build upon each other, you'll need to complete Level 1A - Getting Started with ASL before beginning this course.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		prerequisites: ["level-1a-online-asl-course"],
	},
	{
		id: 8525391954080,
		thinkificId: 2091215,
		title: "Elevating your Signing Skills",
		subTitle: "Level 2A",
		orderInSeries: 3,
		slug: "level-2a-online-asl-course",
		handle: "level-2a-online-asl-course",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "intermediate",
		type: "course",
		instructor: byronDetails,
		meta: {
			title: "Elevating Your ASL Skills | Boost Your Fluency Now",
			description:
				"Take your ASL to the next level with 700+ new vocabulary words. Perfect your receptive skills and cultural understanding with advanced video lessons.",
			keywords:
				"Level 2A ASL course, advanced sign language course, online ASL lessons, earn CEUs ASL, Deaf culture course, ASL receptive practice",
		},
		giftMeta: {
			title: "Gift Elevating Your ASL Skills| Unique Holiday Present",
			description:
				"Give a unique learning experience! This beginner ASL course makes a thoughtful birthday or holiday gift. Includes instant delivery and personal message.",
			keywords:
				"Level 2A ASL course, advanced sign language course, online ASL lessons, earn CEUs ASL, Deaf culture course, ASL receptive practice",
		},
		image: {
			url: "/img/course/level-2a-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Level 2 course continues to build on what you've learned in Level 1.  You'll be able to form sentences, describe people and things, and better understand ASL grammar.",
		overview:
			"This course will build upon students’ fundamental American Sign Language skills. It is designed for those with a basic understanding of fingerspelling, numbers, basic signs, and Deaf culture. Upon completion of this course, you will be familiar with additional vocabulary words, basic grammar, and Deaf culture.",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Over 2.5 hours of on-demand video instruction",
				"Self-paced lessons that save your progress",
				"Nearly 700 vocabulary words you'll start using right away",
				"More than 60 activities to help extend your learning",
				"20 practice interactions that let you follow Dr. Byron one sentence at a time",
				"Each enrolled student earns a Certificate of Completion",
				"50 fingerspelling practice activities",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/byron_header_2a.png",
				altText: "Dr. Byron Bridges",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "2.5+ hours",
			ceuCount: "2",
			access: "Full course access for 1 year!",
		},
		topics: {
			image: {
				url: "/img/home/signing-3.jpg",
				altText: "friends signing in ASL",
				width: 800,
				height: 450,
			},
			list: [
				"Travel",
				"US Cities & States",
				"School & Education",
				"Career & Professions",
				"Feelings",
				"Numbers: Hundreds, thousands, millions",
				"Years",
				"Height, weight & measurement",
				"Time & Duration",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/level-2-mock.jpg",
				altText: "Level 2 course on Laptop, mobile phone and Tablet",
				width: 1064,
				height: 602,
			},
			text: [
				"Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. You’ll also have an opportunity to better practice your receptive skills and fingerspelling – watching Dr. Byron as he practices words and sentences with you. You’re in the best hands for learning and using ASL!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic you'll be exploring. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860667686,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail:
						"/img/course/previews/level-2a/on-demand-videos-thumbnail.png",
				},
			},
			{
				title: "Language Tips & Culture",
				description:
					"Dr. Byron help elevate your sign language with expert language tips and knowledge about Deaf culture to help you better understand the language and the people who use it.",
				video: {
					id: 860667704,
					altText:
						"Level 1A - Getting Started with ASL: Language Tips & Culture video lessons in LMS",
					thumbnail: "/img/course/previews/level-2a/tips-culture-thumbnail.png",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance your receptive skills through interpreting signed sentences you can practice along with.",
				video: {
					id: 860657761,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-2a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next topic. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860651543,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-2a/quiz-thumbnail.png",
				},
			},
		],
		reviews: level2Reviews,
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>Yes.  As all of our courses build upon each other, you'll need to complete Level 1A - Getting Started with ASL & Level 1B - Building on the Basics of ASL before beginning this course.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		prerequisites: ["level-1a-online-asl-course", "level-1b-online-asl-course"],
	},
	{
		id: 8525400146080,
		thinkificId: 2091214,
		title: "Refining your ASL Skills",
		subTitle: "Level 2B",
		orderInSeries: 4,
		slug: "level-2b-online-asl-course",
		handle: "level-2b-online-asl-course",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "intermediate",
		type: "course",
		instructor: byronDetails,
		meta: {
			title: "Refining Your ASL Skills | Advanced Online Course",
			description:
				"Achieve ASL mastery with our highest-level course. Perfect for serious signers ready for advanced vocabulary, storytelling, and cultural immersion.",
			keywords:
				"Level 2B ASL course, advanced sign language skills, online ASL lessons, earn CEUs in ASL, expressive signing techniques, Deaf culture course",
		},
		giftMeta: {
			title: "Gift Refining Your ASL Skills | Fun Holiday Present",
			description:
				"Give a unique learning experience! This beginner ASL course makes a thoughtful birthday or holiday gift. Includes instant delivery and personal message.",
			keywords:
				"Level 2B ASL course, advanced sign language skills, online ASL lessons, earn CEUs in ASL, expressive signing techniques, Deaf culture course",
		},
		image: {
			url: "/img/course/level-2b-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Level 2 course continues to build on what you've learned in Level 1.  You'll be able to form sentences, describe people and things, and better understand ASL grammar.",
		overview:
			"This course will build upon students’ fundamental American Sign Language skills. It is designed for those with a basic understanding of fingerspelling, numbers, basic signs, and Deaf culture. Upon completion of this course, you will be familiar with additional vocabulary words, basic grammar, and Deaf culture.",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Over 2.5 hours of on-demand video instruction",
				"Self-paced lessons that save your progress",
				"Nearly 700 vocabulary words you'll start using right away",
				"More than 50 activities to help extend your learning",
				"20 practice interactions that let you follow Dr. Byron one sentence at a time",
				"Each enrolled student earns a Certificate of Completion",
				"50 fingerspelling practice activities",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/byron_header_2b.png",
				altText: "Dr. Byron Bridges",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "2.5+ hours",
			ceuCount: "2",
			access: "Full course access for 1 year!",
		},
		topics: {
			image: {
				url: "/img/home/signing-3.jpg",
				altText: "friends signing in ASL",
				width: 800,
				height: 450,
			},
			list: [
				"Describing People & Things",
				"Pronouns",
				"Classifiers",
				"Verbs",
				"Describing a Room",
				"Ages",
				"Banking & Accounting",
				"Math Terminology",
				"Phone Numbers",
				"Addresses",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/level-2-mock.jpg",
				altText: "Level 2 course on Laptop, mobile phone and Tablet",
				width: 1064,
				height: 602,
			},
			text: [
				"Each lesson is packed with vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. You’ll also have an opportunity to better practice your receptive skills and fingerspelling – watching Dr. Byron as he practices words and sentences with you. You’re in the best hands for learning and using ASL!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic you'll be exploring. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860667686,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail:
						"/img/course/previews/level-2a/on-demand-videos-thumbnail.png",
				},
			},
			{
				title: "Language Tips & Culture",
				description:
					"Dr. Byron help elevate your sign language with expert language tips and knowledge about Deaf culture to help you better understand the language and the people who use it.",
				video: {
					id: 860667704,
					altText:
						"Level 1A - Getting Started with ASL: Language Tips & Culture video lessons in LMS",
					thumbnail: "/img/course/previews/level-2a/tips-culture-thumbnail.png",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance your receptive skills through interpreting signed sentences you can practice along with.",
				video: {
					id: 860657761,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-2a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next topic. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860651543,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-2a/quiz-thumbnail.png",
				},
			},
		],
		reviews: level2Reviews,
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>Yes.  As all of our courses build upon each other, you'll need to complete Level 1A - Getting Started with ASL, Level 1B - Building on the Basics of ASL, & Level 2A - Elevating your Signing Skills before beginning this course.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		prerequisites: [
			"level-1a-online-asl-course",
			"level-1b-online-asl-course",
			"level-2a-online-asl-course",
		],
	},
	{
		id: 8525665435808,
		title: "Complete Online Course Bundle",
		subTitle: "Levels 1A, 1B, 2A, & 2B",
		orderInSeries: 2,
		slug: "complete-course-bundle",
		handle: "complete-course-bundle",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "beginner",
		type: "bundle",
		instructor: byronDetails,
		meta: {
			title:
				"Learn ASL with the Complete Course Bundle | Accredited Online Courses",
			description:
				"Master ASL from beginner to advanced with our complete course bundle. Get all 4 accredited courses, 2 years access, and premium materials. Save 25% today!",
			keywords:
				"Complete ASL bundle, accredited sign language course, 7 CEUs ASL, advanced ASL course, online ASL certification",
		},
		giftMeta: {
			title: "Ultimate ASL Gift Bundle: All Courses | Special Value",
			description:
				"Give the ultimate language learning gift! Complete ASL course bundle includes all levels, 2 years access, and 25% savings. Perfect meaningful present.",
			keywords:
				"Complete ASL bundle, accredited sign language course, 7 CEUs ASL, advanced ASL course, online ASL certification",
		},
		image: {
			url: "/img/course/complete-bundle-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Complete Online ASL Course gives you the best of both Level 1 & Level 2 courses for one low price!",
		overview:
			"This course will provide students with an introduction to American Sign Language. It is designed for those who are new to sign language and want to learn the basics. Upon completion of this course, you will be familiar with common gestures and signs, fingerspelling, and Deaf culture.",
		lessonCount: 20,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Nearly 700 vocabulary words you&apos;ll start using right away",
				"Over 2.5 hours of on-demand video instruction",
				"More than XX activities and worksheets to help extend your learning",
				"100 fingerspelling practice activities",
				"Self-paced lessons that save your progress",
				"Each enrolled student earns a Certificate of Completion",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/byron_header_complete_bundle_right.png",
				altText: "Dr. Byron Bridges",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "20 weeks (flexible)",
			videoHours: "10 hours",
			ceuCount: "7",
			access: "Full course access for 2 years!",
		},
		topics: {
			image: {
				url: "/img/home/signing-3.jpg",
				altText: "friends signing in ASL",
				width: 800,
				height: 450,
			},
			list: [
				"Facial expressions & body language",
				"Numbers 1 - 100",
				"The ABCs",
				"Colors",
				"Pronouns",
				"Everyday Signs",
				"Common Phrases",
				"Antonyms",
				"Around the House",
				"Hobbies",
				"People",
				"The Body",
				"Clothing",
				"Time & Calendar",
				"Verbs",
				"Directions",
				"Food & Drinks",
				"Animals",
				"Sports",
				"Travel",
				"US Cities & States",
				"School & Education",
				"Career & Professions",
				"Feelings",
				"Numbers: Hundreds, thousands, millions",
				"Years",
				"Height, weight & measurement",
				"Time & Duration",
				"Describing People & Things",
				"Classifiers",
				"Verbs",
				"Describing a Room",
				"Ages",
				"Banking & Accounting",
				"Math Terminology",
				"Phone Numbers",
				"Addresses",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/complete/complete-mock.png",
				altText:
					"complete asl online course on Laptop, mobile phone and Tablet",
				width: 1518,
				height: 801,
			},
			text: [
				"In Level 1, Dr. Byron Bridges will guide you through vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. Then in Level 2 you'll continue your learning journey with 10 all-new video lessons that will help you form sentences, describe people and things, and better understand ASL grammar!",
				"You’ll also have an opportunity to better practice your receptive skills and fingerspelling – watching Dr. Byron as he practices words and sentences with you. You’re in the best hands for learning and using ASL!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic you'll be exploring. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860476508,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail: "/img/course/previews/level-1a/intro-thumbnail.png",
				},
			},
			{
				title: "Deep Dive Topics",
				description:
					"Following the topic introduction, you'll delve into deeper into key topics related to ASL sentence structure and signing. Our interactive videos ensure that you not only understand the signs but also internalize them, enabling you to communicate expressively.",
				image: {
					src: deepDive,
					altText:
						"Level 1A - Getting Started with ASL: Deep Dive Topics in LMS",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance receptive skills through interpreting signed questions and forming mental responses in ASL.",
				video: {
					id: 860452900,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-1a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next topic. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860467560,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: [...level1Reviews, ...level2Reviews],
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>No. As this is the first course in our series we teach you ASL from the beginning.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		bundledCourses: [
			{
				title: "Getting Started with ASL",
				subTitle: "Level 1A",
				lessonCount: 5,
				image: {
					url: "/img/course/level-1a-image_SM.png",
					altText: "Getting Started with ASL - Level 1A course",
				},
				handle: "level-1a-online-asl-course",
				description:
					"A perfect introduction to ASL, gain basic conversation skills and a foundational understanding of ASL vocabulary and grammar. With practice and expert guidance.",
			},
			{
				title: "Building on the Basics of ASL",
				subTitle: "Level 1B",
				lessonCount: 5,
				image: {
					url: "/img/course/level-1b-image_SM.png",
					altText: "Asking & Answering Questions - Level 1B course",
				},
				handle: "level-1b-online-asl-course",
				description:
					"This follow-up course to 'Level 1A - Discovering Sign Language' is the perfect continuation for young sign language enthusiasts. Delve deeper into ASL by expanding vocabulary, exploring numbers, mastering grammar, and diving into the rich world of Deaf culture. With engaging activities and nurturing guidance, your child will flourish on this exciting ASL adventure.",
			},
			{
				title: "Elevating your Signing Skills",
				subTitle: "Level 2A",
				lessonCount: 5,
				image: {
					url: "/img/course/level-2a-image_SM.png",
					altText: "ASL Grammar & Pronouns - Level 2A course",
				},
				handle: "level-2a-online-asl-course",
				description:
					"Build on the solid foundation you've created in Levels 1A & 1B! Dive into more advanced vocabulary, tackle complex grammar rules, and gain a deeper understanding of Deaf culture. Elevate your ASL skills and unlock new opportunities for communication and connection!",
			},
			{
				title: "Refining your ASL Skills",
				subTitle: "Level 2B",
				lessonCount: 5,
				image: {
					url: "/img/course/level-2b-image_SM.png",
					altText: "Storytelling - Level 2B course",
				},
				handle: "level-2b-online-asl-course",
				description:
					"Our Level 2B course is designed for those looking to elevate their signing abilities. Explore the art of storytelling in ASL, refining your expressive and receptive skills while delving even deeper into advanced vocabulary, nuanced grammar, and Deaf culture.",
			},
		],
		prerequisites: [],
	},
	{
		id: 9797391548576,
		title: "ASL Essentials Bundle",
		subTitle: "Levels 1A & 1B",
		orderInSeries: 1,
		slug: "asl-essentials-bundle",
		handle: "asl-essentials-bundle",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "adult",
		skillLevel: "beginner",
		type: "bundle",
		instructor: byronDetails,
		meta: {
			title: "ASL Essentials: Complete Beginner Bundle | Save 10% Today",
			description:
				"Start your ASL journey with our best-selling beginner bundle. Master essential signs, grammar, and Deaf culture. Save 10% on two comprehensive courses combined!",
			keywords:
				"ASL bundle, accredited ASL course, CEUs sign language course, learn ASL online, American Sign Language video lessons",
		},
		giftMeta: {
			title: "Perfect Gift Idea: ASL Essentials Bundle | Instant Delivery",
			description:
				"Give the gift of Sign Language! Our best-selling ASL bundle makes a unique present. Instant digital delivery, gift message option, and 1-year access included.",
			keywords:
				"ASL bundle, accredited ASL course, CEUs sign language course, learn ASL online, American Sign Language video lessons",
		},
		image: {
			url: "/img/course/asl-essentials-bundle_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Complete Online ASL Course gives you the best of both Level 1 & Level 2 courses for one low price!",
		overview:
			"This course will provide students with an introduction to American Sign Language. It is designed for those who are new to sign language and want to learn the basics. Upon completion of this course, you will be familiar with common gestures and signs, fingerspelling, and Deaf culture.",
		lessonCount: 20,
		header: {
			description: "Something about the Essentials Bundle here....",
			highlights: [
				"Nearly 700 vocabulary words you&apos;ll start using right away",
				"Over 2.5 hours of on-demand video instruction",
				"More than XX activities and worksheets to help extend your learning",
				"100 fingerspelling practice activities",
				"Self-paced lessons that save your progress",
				"Each enrolled student earns a Certificate of Completion",
				"Fun & unintimidating - Suitable for ages 15 to 100!",
			],
			image: {
				url: "/img/course/asl_essentials_header.png",
				altText: "Dr. Byron Bridges",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 776724320,
			title: "Sign Language 101 Level 1 Intro Video",
			thumbnail: "/img/course/lesson-1-intro.png",
		},
		stats: {
			duration: "10 weeks (flexible)",
			videoHours: "5 hours",
			ceuCount: "3",
			access: "Full course access for 1 year!",
		},
		topics: {
			image: {
				url: "/img/home/signing-3.jpg",
				altText: "friends signing in ASL",
				width: 800,
				height: 450,
			},
			list: [
				"Facial expressions & body language",
				"Numbers 1 - 100",
				"The ABCs",
				"Colors",
				"Pronouns",
				"Everyday Signs",
				"Common Phrases",
				"Antonyms",
				"Around the House",
				"Hobbies",
				"People",
				"The Body",
				"Clothing",
				"Time & Calendar",
				"Verbs",
				"Directions",
				"Food & Drinks",
				"Animals",
				"Sports",
				"Fingerspelling",
				"Receptive Practice",
				"Deaf Culture",
				"Language Tips",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/course/complete/complete-mock.png",
				altText:
					"complete asl online course on Laptop, mobile phone and Tablet",
				width: 1518,
				height: 801,
			},
			text: [
				"In Level 1, Dr. Byron Bridges will guide you through vocabulary words, numbers, tips about learning American Sign Language, and special knowledge about Deaf culture. Then in Level 2 you'll continue your learning journey with 10 all-new video lessons that will help you form sentences, describe people and things, and better understand ASL grammar!",
				"You’ll also have an opportunity to better practice your receptive skills and fingerspelling – watching Dr. Byron as he practices words and sentences with you. You’re in the best hands for learning and using ASL!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic you'll be exploring. Dr. Byron Bridges breaks down complex concepts into easily digestible segments, making ASL accessible and enjoyable for all learners.",
				// image: introduction,
				video: {
					id: 860476508,
					altText:
						"Level 1A - Getting Started with ASL: On Demand video lessons in LMS",
					thumbnail: "/img/course/previews/level-1a/intro-thumbnail.png",
				},
			},
			{
				title: "Deep Dive Topics",
				description:
					"Following the topic introduction, you'll delve into deeper into key topics related to ASL sentence structure and signing. Our interactive videos ensure that you not only understand the signs but also internalize them, enabling you to communicate expressively.",
				image: {
					src: deepDive,
					altText:
						"Level 1A - Getting Started with ASL: Deep Dive Topics in LMS",
				},
			},
			{
				title: "Receptive Practice",
				description:
					"Dr. Byron helps improve your ASL communication by teaching quick deciphering of fingerspelled words. You'll also enhance receptive skills through interpreting signed questions and forming mental responses in ASL.",
				video: {
					id: 860452900,
					altText:
						"Level 1A - Getting Started with ASL: Receptive Practice video in LMS",
					thumbnail: "/img/course/previews/level-1a/receptive-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your knowledge and ensure you're ready to move on to the next topic. You'll also have access to a final assessment to test your overall comprehension of the course material.",
				video: {
					id: 860467560,
					altText: "Level 1A - Getting Started with ASL: Quiz in LMS",
					thumbnail: "/img/course/previews/level-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: level1Reviews,
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>No. As this is the first course in our series we teach you ASL from the beginning.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Level 1 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 1A & 1B courses cover the same great content (just refreshed) as what was in our Level 1 - Online ASL Course.  You'll be able to continue your learning wiith our <a href="/courses/level-2a-online-asl-course" class="underline text-black text-bold hover:text-aquamarine-blue-800" >Level 2A course</a>.</p>`,
				link: "new-course-levels-1",
			},
			{
				question:
					"I completed the original Level 2 - Online ASL Course.  What course should I take next?",
				answer: `<p>Our Levels 2A & 2B courses cover the same great content as what was in our Level 2 - Online ASL Course.  To continue your ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-2",
			},
		],
		bundledCourses: [
			{
				title: "Getting Started with ASL",
				subTitle: "Level 1A",
				lessonCount: 5,
				image: {
					url: "/img/course/level-1a-image_SM.png",
					altText: "Getting Started with ASL - Level 1A course",
				},
				handle: "level-1a-online-asl-course",
				description:
					"A perfect introduction to ASL, gain basic conversation skills and a foundational understanding of ASL vocabulary and grammar. With practice and expert guidance.",
			},
			{
				title: "Building on the Basics of ASL",
				subTitle: "Level 1B",
				lessonCount: 5,
				image: {
					url: "/img/course/level-1b-image_SM.png",
					altText: "Asking & Answering Questions - Level 1B course",
				},
				handle: "level-1b-online-asl-course",
				description:
					"This follow-up course to 'Level 1A - Discovering Sign Language' is the perfect continuation for young sign language enthusiasts. Delve deeper into ASL by expanding vocabulary, exploring numbers, mastering grammar, and diving into the rich world of Deaf culture. With engaging activities and nurturing guidance, your child will flourish on this exciting ASL adventure.",
			},
		],
		prerequisites: [],
	},
	{
		id: 9417752248480,
		thinkificId: 2264879,
		title: "Discovering Sign Language",
		subTitle: "Level 1A",
		orderInSeries: 1,
		level: "Level 1A",
		slug: "kids-asl-course-level-1a",
		handle: "sign-language-101-for-kids-level-1a",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "kids",
		skillLevel: "beginner",
		type: "course",
		instructor: sandraDetails,
		meta: {
			title:
				"Discovering Sign Language for Kids | Beginner ASL Course for Children",
			description:
				"Make learning ASL fun for your child! Engaging videos, games, and activities teach basic signs and grammar. Perfect for ages 5-12.",
			keywords:
				"ASL for kids, sign language course for children, online ASL lessons for kids, beginner ASL course, ASL certification for kids",
		},
		giftMeta: {
			title: "Gift Discovering Sign Language for Kids | Instant Delivery",
			description:
				"Give a meaningful gift kids love! ASL courses make perfect presents for birthdays, holidays, or any occasion. Instant email delivery with personal message.",
			keywords:
				"ASL bundle, accredited ASL course, CEUs sign language course, learn ASL online, American Sign Language video lessons",
		},
		image: {
			url: "/img/course/kids-level-1a-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Complete Online ASL Course gives you the best of both Level 1 & Level 2 courses for one low price!",
		overview:
			"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Start signing with friends and family right away",
				"Learn through fun games and interactive activities",
				"Build confidence with kid-friendly lessons",
				"Self-paced lessons that save your child's progress",
				"Great for home-schooling or as additional classroom support",
				"Each enrolled student earns a Certificate of Completion",
				"Designed for learners ages 8-15!",
			],
			image: {
				url: "/img/course/kids_header_1a.png",
				altText: "Sandra Mae Frank teaches kids sign language",
				width: 500,
				height: 533,
			},
		},
		previewVideo: {
			id: 687299401,
			title: "Sign Language 101 for Kids Intro",
			thumbnail: "/img/course/intro-video.png",
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "60 videos",
			access: "Full course access for 1 year!",
		},
		topics: {
			video: {
				id: 860949891,
				altText: "Kid signing Want to Learn ASL? Come with me!",
				thumbnail: "/img/course/previews/kids-1a/come-learn-asl-thumbnail.png",
			},
			list: [
				"Letters, Names, & Numbers",
				"Colors, Ages, & Gestures",
				"Animal Signs",
				"Family Signs",
				"Everyday Phrases",
				"ASL Grammar",
				"Deaf Culture",
				"Fingerspelling Practice",
				"Quizzes to check your knowledge",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/home/multi_mockup3.png",
				altText: "kids can learn sign language online",
				width: 1518,
				height: 801,
			},
			text: [
				"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic your child will be exploring. Sandra breaks down signs and topics into simple instructions, making ASL accessible and enjoyable for all learners.",
				video: {
					id: 860941971,
					altText:
						"Level 1A - Discovering Sign Language: On Demand video lessons in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/on-demand-lessons-thumbnail.png",
				},
			},
			{
				title: "ASL Grammar & Culture",
				description:
					"Throughout the course your child will learn about ASL grammar and Deaf culture. These lessons will help them better understand the language and the people who use it.",
				video: {
					id: 860936547,
					altText:
						"Level 1A - Discovering Sign Language: ASL Grammar and Culture videos in LMS",
					thumbnail: "/img/course/previews/kids-1a/grammar-thumbnail.png",
				},
			},
			{
				title: "Fingerspelling Practice",
				description:
					"Sandra helps improve your child's ASL communication by teaching how to fingerspell words.",
				video: {
					id: 860930155,
					altText:
						"Level 1A - Discovering Sign Language: Fingerspelling Practice video in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/fingerspelling-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your child's knowledge and ensure they're ready to move on to the next topic.",
				video: {
					id: 860671509,
					altText: "Level 1A - Discovering Sign Language: Quiz in LMS",
					thumbnail: "/img/course/previews/kids-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: [],
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>No. As this is the first course in our series we teach you ASL from the beginning.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Sign Language 101 for Kids course.  What course should I take next?",
				answer: `<p>Our Levels Kid's 1A & 1B courses cover the same great content as what was in our original Sign Language 101 for Kids course. To continue your child's ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-1",
			},
		],
		prerequisites: [],
	},
	{
		id: 9417821880480,
		thinkificId: 2375779,
		title: "Going Beyond the Basics",
		subTitle: "Level 1B",
		orderInSeries: 2,
		level: "Level 1B",
		slug: "kids-asl-course-level-1b",
		handle: "sign-language-101-for-kids-level-1b",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "kids",
		skillLevel: "beginner",
		type: "course",
		instructor: sandraDetails,
		meta: {
			title: "Kids' ASL Level 1B: Fun Beginner Course | Ages 5-12",
			description:
				"Help your child advance their ASL skills with engaging Level 1B lessons. Fun videos, games, and cultural activities perfect for young learners.",
			keywords:
				"ASL for kids Level 1B, advanced ASL course for children, sign language lessons for kids, online ASL with certificate, Deaf culture for kids",
		},
		giftMeta: {
			title: "Gift Going Beyond the Basics | Fun Holiday Gifts for Kids",
			description:
				"Give a meaningful gift kids love! ASL courses make perfect presents for birthdays, holidays, or any occasion. Instant email delivery with personal message.",
			keywords:
				"ASL for kids Level 1B, advanced ASL course for children, sign language lessons for kids, online ASL with certificate, Deaf culture for kids",
		},
		image: {
			url: "/img/course/kids-level-1b-image_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Complete Online ASL Course gives you the best of both Level 1 & Level 2 courses for one low price!",
		overview:
			"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
		lessonCount: 5,
		header: {
			description:
				"Take your learning to the next level with more than 100 bite-size videos and activities designed to help you start signing today!",
			highlights: [
				"Express themselves more freely in ASL",
				"Learn about fascinating Deaf culture and traditions",
				"Build greater confidence in their signing skills",
				"Self-paced lessons that save your child's progress",
				"Great for home-schooling or as additional classroom support",
				"Each enrolled student earns a Certificate of Completion",
				"Designed for learners ages 8-15!",
			],
			image: {
				url: "/img/course/kids_header_1b.png",
				altText: "Sandra Mae Frank teaches kids sign language",
				width: 500,
				height: 533,
			},
		},
		stats: {
			duration: "5 weeks (flexible)",
			videoHours: "60 videos",
			access: "Full course access for 1 year!",
		},
		topics: {
			video: {
				id: 860963438,
				altText: "Kid signing Want to Learn ASL? Come with me!",
				thumbnail:
					"/img/course/previews/kids-1a/come-learn-asl-2-thumbnail.png",
			},
			list: [
				"School Signs",
				"Food Signs",
				"Sports Signs",
				"Feelings & Emotions",
				"Occupations",
				"ASL Grammar",
				"Deaf Culture",
				"Fingerspelling Practice",
				"Quizzes to check your knowledge",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/home/multi_mockup3.png",
				altText: "kids can learn sign language online",
				width: 1518,
				height: 801,
			},
			text: [
				"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic your child will be exploring. Sandra breaks down signs and topics into simple instructions, making ASL accessible and enjoyable for all learners.",
				video: {
					id: 860941971,
					altText:
						"Level 1A - Discovering Sign Language: On Demand video lessons in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/on-demand-lessons-thumbnail.png",
				},
			},
			{
				title: "ASL Grammar & Culture",
				description:
					"Throughout the course your child will learn about ASL grammar and Deaf culture. These lessons will help them better understand the language and the people who use it.",
				video: {
					id: 860936547,
					altText:
						"Level 1A - Discovering Sign Language: ASL Grammar and Culture videos in LMS",
					thumbnail: "/img/course/previews/kids-1a/grammar-thumbnail.png",
				},
			},
			{
				title: "Fingerspelling Practice",
				description:
					"Sandra helps improve your child's ASL communication by teaching how to fingerspell words.",
				video: {
					id: 860930155,
					altText:
						"Level 1A - Discovering Sign Language: Fingerspelling Practice video in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/fingerspelling-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your child's knowledge and ensure they're ready to move on to the next topic.",
				video: {
					id: 860671509,
					altText: "Level 1A - Discovering Sign Language: Quiz in LMS",
					thumbnail: "/img/course/previews/kids-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: [],
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>Yes.  As all of our courses build upon each other, your child will need to complete Level 1A - Discovering Sign Language before beginning this course.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Sign Language 101 for Kids course.  What course should I take next?",
				answer: `<p>Our Levels Kid's 1A & 1B courses cover the same great content as what was in our original Sign Language 101 for Kids course. To continue your child's ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-1",
			},
		],
		prerequisites: ["sign-language-101-for-kids-level-1a"],
	},
	{
		id: 10954277388448,
		title: "ASL Essentials Bundle for Kids",
		subTitle: "Levels 1A & 1B",
		orderInSeries: 3,
		slug: "asl-essentials-bundle-for-kids",
		handle: "asl-essentials-bundle-for-kids",
		syllabusUrl: "/files/Level_2_Course_Syllabus.pdf",
		audience: "kids",
		skillLevel: "beginner",
		type: "bundle",
		instructor: sandraDetails,
		meta: {
			title: "Kids' ASL Bundle: Fun Sign Language Course | Special Offer",
			description:
				"Give your child the gift of ASL with our engaging kids' bundle. Fun videos, interactive games, and easy-to-follow lessons. Save 10% on combined courses today!",
			keywords:
				"ASL for kids bundle, sign language course for children, online ASL with certificate, interactive ASL activities, Deaf culture for kids",
		},
		giftMeta: {
			title: "Unique Kids Gift: ASL Essentials Bundle | Ages 5-12",
			description:
				"Looking for a unique children's gift? Give the joy of ASL learning! Fun interactive lessons perfect for ages 5-12. Instant delivery with personal message.",
			keywords:
				"ASL for kids bundle, sign language course for children, online ASL with certificate, interactive ASL activities, Deaf culture for kids",
		},
		image: {
			url: "/img/course/asl-essentials-bundle-for-kids_LG.png",
			altText: "Free ASL class about Dr. Bridges & Our Courses",
		},
		description:
			"Our Complete Online ASL Course gives you the best of both Level 1 & Level 2 courses for one low price!",
		overview:
			"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
		lessonCount: 10,
		header: {
			description: "Something about the Essentials Bundle here....",
			highlights: [
				"Created and taught by Deaf curriculum specialists",
				"100+ activities included in our digital course workbook",
				"5+ hours of engaging ASL videos",

				"Self-paced lessons that save your child's progress",
				"Great for home-schooling or as additional classroom support",
				"Each enrolled student earns a Certificate of Completion",
				"Designed for learners ages 8-15!",
			],
			image: {
				url: "/img/course/essentials-bundle-kids-header.png",
				altText: "Sandra Mae Frank teaches kids sign language",
				width: 500,
				height: 533,
			},
		},
		stats: {
			duration: "10 weeks (flexible)",
			videoHours: "120 videos",
			access: "Full course access for 1 year!",
		},
		topics: {
			video: {
				id: 860949891,
				altText: "Kid signing Want to Learn ASL? Come with me!",
				thumbnail: "/img/course/previews/kids-1a/come-learn-asl-thumbnail.png",
			},
			list: [
				"Letters, Names, & Numbers",
				"Colors, Ages, & Gestures",
				"Animal Signs",
				"Family Signs",
				"Everyday Phrases",
				"School Signs",
				"Food Signs",
				"Sports Signs",
				"Feelings & Emotions",
				"Occupations",
				"ASL Grammar",
				"Deaf Culture",
				"Fingerspelling Practice",
				"Quizzes to check your knowledge",
			],
		},
		whatYouWillLearn: {
			image: {
				url: "/img/home/multi_mockup3.png",
				altText: "kids can learn sign language online",
				width: 1518,
				height: 801,
			},
			text: [
				"Your child will learn the foundations of ASL alongside celebrated Deaf actress and ASL teacher Sandra Mae Frank. Each unit ends with a quiz designed to support your child's learning and to help them track their progress. In this fun, interactive course, we'll explore vocabulary words, numbers, grammar, tips about learning American Sign Language, fingerspelling, special facts about Deaf culture. BONUS: Your child can follow along with our free interactive PDF workbook as they learn, practice, and test their ASL knowledge ($25 value). It's like having a teacher right there with them!",
			],
		},
		features: [
			{
				title: "On-Demand Video Lessons",
				description:
					"Each unit opens with a video that introduces the new topic your child will be exploring. Sandra breaks down signs and topics into simple instructions, making ASL accessible and enjoyable for all learners.",
				video: {
					id: 860941971,
					altText:
						"Level 1A - Discovering Sign Language: On Demand video lessons in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/on-demand-lessons-thumbnail.png",
				},
			},
			{
				title: "ASL Grammar & Culture",
				description:
					"Throughout your lesson your child will learn about ASL grammar and Deaf culture. These lessons will help them better understand the language and the people who use it.",
				video: {
					id: 860936547,
					altText:
						"Level 1A - Discovering Sign Language: ASL Grammar and Culture videos in LMS",
					thumbnail: "/img/course/previews/kids-1a/grammar-thumbnail.png",
				},
			},
			{
				title: "Fingerspelling Practice",
				description:
					"Sandra helps improve your child's ASL communication by teaching how to fingerspell words.",
				video: {
					id: 860930155,
					altText:
						"Level 1A - Discovering Sign Language: Fingerspelling Practice video in LMS",
					thumbnail:
						"/img/course/previews/kids-1a/fingerspelling-thumbnail.png",
				},
			},
			{
				title: "Quizzes to Test Your Knowledge",
				description:
					"Each unit ends with a quiz to test your child's knowledge and ensure they're ready to move on to the next topic.",
				video: {
					id: 860671509,
					altText: "Level 1A - Discovering Sign Language: Quiz in LMS",
					thumbnail: "/img/course/previews/kids-1a/quiz-thumbnail.png",
				},
			},
		],
		reviews: [],
		courseFaq: [
			{
				question: "Is there a prerequisite for this course?",
				answer: `<p>No. As this is the first course in our series we teach you ASL from the beginning.</p>`,
				link: "course-prequisite",
			},
			{
				question:
					"I completed the original Sign Language 101 for Kids course.  What course should I take next?",
				answer: `<p>Our Levels Kid's 1A & 1B courses cover the same great content as what was in our original Sign Language 101 for Kids course. To continue your child's ASL education we recommend checking out the <a href="/books" class="underline text-black text-bold hover:text-aquamarine-blue-800" >books</a> we've curated for our learners.</p>`,
				link: "new-course-levels-1",
			},
		],
		bundledCourses: [
			{
				title: "Discovering Sign Language",
				subTitle: "Level 1A",
				lessonCount: 5,
				image: {
					url: "/img/course/kids-level-1a-image_SM.png",
					altText: "Level 1A - Discovering Sign Language",
				},
				handle: "sign-language-101-for-kids-level-1a",
				description:
					"This course is the perfect introduction to the enchanting world of American Sign Language (ASL). Young learners will acquire fundamental conversation skills while building a solid foundation in ASL vocabulary and grammar. With fun practice activities and expert guidance, your child will be signing confidently in no time.",
			},
			{
				title: "Going Beyond the Basics",
				subTitle: "Level 1B",
				lessonCount: 5,
				image: {
					url: "/img/course/kids-level-1b-image_SM.png",
					altText: "Level 1B - Going Beyond the Basics",
				},
				handle: "sign-language-101-for-kids-level-1b",
				description:
					"This follow-up course to 'Level 1A - Discovering Sign Language' is the perfect continuation for young sign language enthusiasts. Delve deeper into ASL by expanding vocabulary, exploring numbers, mastering grammar, and diving into the rich world of Deaf culture. With engaging activities and nurturing guidance, your child will flourish on this exciting ASL adventure.",
			},
		],
		prerequisites: [],
	},
];
