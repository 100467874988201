import Link from "next/link";
import { twMerge } from "tailwind-merge";

export default function Announcement({ announcement, isVisible }) {
	const classNames =
		"text-sm text-center w-full opacity-0 invisible row-start-1 row-span-1 col-start-1 col-span-1 transition-all duration-1000 -translate-3";

	if (announcement.url) {
		return (
			<Link
				href={announcement.url}
				className={twMerge(
					classNames,
					isVisible && "opacity-100 visible translate-y-0"
				)}
			>
				{announcement.text}
			</Link>
		);
	}

	return (
		<p
			className={twMerge(
				classNames,
				isVisible && "opacity-100 visible translate-y-0"
			)}
		>
			{announcement.text}
		</p>
	);
}
