import { createContext, useContext } from "react";

import { useQuery } from "@tanstack/react-query";

const CrossSellAnnouncements = createContext([]);
import axios from "axios";
const fetcher = (url) => axios.get(url).then((res) => res.data);

const CrossSellAnnoucementsContext = ({ children }) => {
	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["cross-sell-announcements"],
		queryFn: () => fetcher(`/api/sanity/cross-sell-announcements`),
	});

	return (
		<CrossSellAnnouncements.Provider
			value={{
				isLoading,
				isFetching,
				announcements: data,
			}}
		>
			{children}
		</CrossSellAnnouncements.Provider>
	);
};

export const useCrossSellAnnoucementsContext = () => {
	return useContext(CrossSellAnnouncements);
};

export default CrossSellAnnoucementsContext;
