import Link from "next/link";
import Image from "next/image";
import { twMerge } from "tailwind-merge";

const baseStyles = {
	solid:
		"group whitespace-nowrap inline-flex w-full  items-center justify-center  shadow-sm rounded-full py-4 px-8 text-base font-semibold  focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
	outline:
		"group font-semibold inline-flex w-full border-2  items-center justify-center rounded-full py-4 px-8 text-sm focus:outline-none",
};
//removed sm:w-auto

const variantStyles = {
	solid: {
		slate:
			"bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-blue-800",
		blue: "bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-800",
		white:
			"bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-blue-900",
		yellow:
			"bg-yellow-300 text-black hover:bg-yellow-400 hover:bg-yellow-400 active:text-black focus-visible:outline-blue-900",
		primary:
			"bg-yellow-300 text-black hover:bg-yellow-400 hover:bg-yellow-400 active:text-black focus-visible:outline-blue-900",
		secondary:
			"bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
	},
	outline: {
		slate:
			" border-slate-600 hover:border-slate-300 text-slate-700 hover:text-slate-900  active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 ",
		white:
			"ring-white text-white hover:ring-slate-500 active:ring-slate-700 active:text-white focus-visible:outline-white",
		blue: "ring-blue-200 text-blue-700 hover:text-blue-900 hover:ring-blue-300 active:bg-blue-100 active:text-blue-600 focus-visible:outline-blue-600 focus-visible:ring-blue-300",
		red: "ring-red-200 text-red-700 hover:text-red-900 hover:ring-red-300 active:bg-red-100 active:text-red-600 focus-visible:outline-red-600 focus-visible:ring-red-300",
	},
};

const getSpinnerColor = (variant, color) => {
	switch (variant) {
		case "solid":
			if (color == "white") {
				return "text-slate-900";
			} else return "text-slate-white";
		case "outline":
			return "text-slate-700";
	}
};

export function Button({
	variant = "solid",
	color = "slate",
	spinner = false,
	disabled = false,
	openInNewTab = false,
	className,
	href,
	children,
	...props
}) {
	className = twMerge(
		baseStyles[variant],
		variantStyles[variant][color],
		disabled && "opacity-50 cursor-not-allowed",
		className
	);

	return openInNewTab ? (
		<a
			href={href}
			target="_blank"
			rel="noreferrer noopener"
			role="button"
			className={className}
			{...props}
			disabled={disabled}
		>
			<>{children}</>
		</a>
	) : href ? (
		<Link role="button" href={href} className={className} {...props}>
			{spinner ? (
				<div role="status">
					<Image
						src="/img/brand/sl101_logo_square.png"
						width="10"
						height="10"
						className="animate-spin"
						alt="Sign Language 101 spinning logo"
					/>
					<span className="sr-only">Loading...</span>
				</div>
			) : (
				<>{children}</>
			)}
		</Link>
	) : (
		<button role="button" className={className} {...props} disabled={disabled}>
			{spinner ? (
				<div role="status">
					<Image
						src="/img/brand/sl101_logo_square.png"
						width="24"
						height="24"
						className="animate-spin mr-2"
						alt="Sign Language 101 spinning logo"
					/>
					<span className="sr-only">Loading...</span>
				</div>
			) : (
				<>{children}</>
			)}
		</button>
	);
}
