import { sendGTMEvent } from "@next/third-parties/google";

import { getSimpleProductId, getSimpleProductVariantId } from "./shopify-utils";

const clearDataLayerEcomm = () => {
	window.dataLayer = window.dataLayer || [];
	dataLayer.push({ ecommerce: undefined });
};

export const gtmPageView = (rest) => {
	sendGTMEvent({
		event: "page_view",
		url: window.location.href,
		...rest,
	});
};

export const ga_logProductDetailView = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "view_item",
		item: {
			name: productDetails.name,
			variantId: getSimpleProductVariantId(productDetails.id),
			sku: productDetails.sku,
			category: productDetails.category,
			quantity: 1,
			price: productDetails.price,
		},
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					item_category: productDetails.category,
					quantity: 1,
					price: productDetails.price,
					id: getSimpleProductVariantId(productDetails.id),
					sku: productDetails?.sku,
				},
			],
		},
		// fb: {
		// 	content_ids: [getSimpleProductVariantId(productDetails.id)], // ID of the product
		// 	content_category: productDetails.category, //Category of the page/product.
		// 	content_name: productDetails.name, // Name of the product added to cart
		// 	content_type: "product", // Type of the content, typically 'product' or 'product_group'
		// 	currency: "USD", // TODO: Use selected currency
		// 	value: productDetails.price, // Value of the product in terms of price
		// },
	});
	clearDataLayerEcomm();
};

export const ga_logProductImpression = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "view_item_list",
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					sku: productDetails?.sku,
					item_category: productDetails.category,
					quantity: 1,
					item_list_name: productDetails.listName,
					price: productDetails.price,
				},
			],
		},
	});
	clearDataLayerEcomm();
};

export const ga_logProductsImpression = (products) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "view_item_list",
		ecommerce: {
			items: products,
		},
	});
	clearDataLayerEcomm();
};

export const ga_logPromotionImpression = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "view_promotion",
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					sku: productDetails?.sku,
					item_category: productDetails.category,
					quantity: 1,
					price: productDetails.price,
					promotion_id: productDetails.promotionId,
					promotion_name: productDetails.promotionName,
					creative_slot: productDetails.creativeSlot,
					location_id: productDetails.locationId,
				},
			],
		},
	});
	clearDataLayerEcomm();
};

export const ga_logPromotionsImpression = (promotions) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "view_promotion",
		ecommerce: {
			items: promotions,
		},
	});
	clearDataLayerEcomm();
};

export const ga_logPromotionClick = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "select_promotion",
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					sku: productDetails?.sku,
					item_category: productDetails.category,
					quantity: 1,
					price: productDetails.price,
					promotion_id: productDetails.promotionId,
					promotion_name: productDetails.promotionName,
					creative_slot: productDetails.creativeSlot,
					location_id: productDetails.locationId,
				},
			],
		},
	});
	clearDataLayerEcomm();
};

export const ga_addItemToCart = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "add_to_cart",
		item: {
			name: productDetails.name,
			variantId: getSimpleProductVariantId(productDetails.id),
			sku: productDetails.sku,
			price: productDetails.price,
			category: productDetails.category,
			currency: "USD", // TODO: Use selected currency
			quantity: productDetails.quantity,
		},
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					sku: productDetails?.sku,
					item_category: productDetails.category,
					quantity: productDetails.quantity,
					price: productDetails.price,
					id: getSimpleProductVariantId(productDetails.id),
				},
			],
		},
		tt: {
			contents: [
				{
					content_id: getSimpleProductVariantId(productDetails.id),
					content_name: productDetails.name,
					quantity: productDetails.quantity,
					price: productDetails.price,
				},
			],
		},
	});
	clearDataLayerEcomm();
};

export const ga_removeItemFromCart = (productDetails) => {
	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "remove_from_cart",
		ecommerce: {
			items: [
				{
					item_name: productDetails.name,
					item_id: productDetails.id,
					sku: productDetails?.sku,
					item_category: productDetails.category,
					quantity: productDetails.quantity,
					price: productDetails.price,
				},
			],
		},
	});
	clearDataLayerEcomm();
};

export const ga_beginCheckout = (cartItems) => {
	const checkoutItems = cartItems.map((product) => {
		return {
			item_name: product.name,
			item_id: product.id,
			item_category: product.category,
			quantity: product.quantity,
			price: product.price,
		};
	});

	// window.dataLayer = window.dataLayer || [];
	sendGTMEvent({
		event: "begin_checkout",
		ecommerce: {
			items: checkoutItems,
		},
	});
	// clearDataLayerEcomm();
};

export const getUrlWithLinkerParam = (url) => {
	//Adds google analytics tracking code to cart URL for tracking of session
	if (typeof ga !== "undefined") {
		try {
			let client_id = ga.getAll()[0].get("linkerParam");

			if (client_id.length > 0) {
				url += "&" + client_id;
			}
		} catch (err) {
			console.debug("ga not available");
		}
	}

	return url;
};

export const getUrlWithClientSession = async (url) => {
	//Adds google analytics tracking code to cart URL for tracking of session

	let clientId = await getClientId();

	let sessionId = await getSessionId();

	if (clientId != "" && sessionId != "") {
		return `${url}?clientId=${clientId}&sessionId=${sessionId}`;
	} else {
		return url;
	}
};

// export const logFBAddToCart = (items) => {
// 	if (typeof fbq !== "undefined") {
// 		fbq("track", "AddToCart", items);
// 	}
// };

const getClientId = () => {
	return new Promise((resolve, reject) => {
		try {
			if (typeof window.gtag !== "undefined") {
				const fallback = window.setTimeout(function () {
					//after 4 seconds, assume the script is blocked
					resolve("");
				}, 2000);

				window.gtag("get", "G-LR4Q7WKS1E", "client_id", (client_id) => {
					window.clearTimeout(fallback);
					resolve(client_id);
				});
			} else {
				reject("");
			}
		} catch (err) {
			reject("");
		}
	});
};

const getSessionId = () => {
	return new Promise((resolve, reject) => {
		try {
			if (typeof window.gtag !== "undefined") {
				const fallback = window.setTimeout(function () {
					//after 4 seconds, assume the script is blocked
					resolve("");
				}, 2000);

				window.gtag("get", "G-LR4Q7WKS1E", "session_id", (session_id) => {
					window.clearTimeout(fallback);
					resolve(session_id);
				});
			} else {
				reject("");
			}
		} catch (err) {
			reject("");
		}
	});
};
