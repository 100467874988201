import { useEffect } from "react";
import { AnalyticsEventName } from "@shopify/hydrogen-react";
import { useShopifyAnalytics } from "@/context/use-shopify-analytics";

export default function ShopifyAnalytics() {
	const { sendPageView, pathname } = useShopifyAnalytics();
	useShopifyAnalytics;
	useEffect(() => {
		sendPageView(AnalyticsEventName.PAGE_VIEW);
	}, [pathname, sendPageView]);
	return null;
}
