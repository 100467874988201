import { usePathname } from "next/navigation";
import {
	AnalyticsEventName,
	getClientBrowserParameters,
	sendShopifyAnalytics,
	ShopifyAnalyticsProduct,
	ShopifyPageViewPayload,
	ShopifySalesChannel,
	useShopifyCookies,
} from "@shopify/hydrogen-react";
import { useCookies } from "react-cookie";

const SHOP_ID = process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ID;

// type SendPageViewPayload = {
//     pageType?: string;
//     products?: ShopifyAnalyticsProduct[];
//     collectionHandle?: string;
//     searchString?: string;
//     totalValue?: number;
//     cartId?: string;
// };

// type SendAddToCartPayload = {
//     cartId: string;
//     products?: ShopifyAnalyticsProduct[];
//     totalValue?: ShopifyPageViewPayload['totalValue'];
// };

export function useShopifyAnalytics() {
	const pathname = usePathname();
	const [cookies] = useCookies();

	// Send page view event
	const sendPageView = (eventName, payload) => {
		if (cookies && cookies["cookieyes-analytics"] == "yes") {
			return sendShopifyAnalytics({
				eventName,
				payload: {
					...getClientBrowserParameters(),
					hasUserConsent: true,
					shopifySalesChannel: ShopifySalesChannel.headless,
					shopId: `gid://shopify/Shop/${SHOP_ID}`,
					currency: "USD",
					acceptedLanguage: "EN",
					...payload,
				},
			});
		}
		return;
	};

	// Send add to cart event
	const sendAddToCart = ({ cartId, totalValue, products }) =>
		sendPageView(AnalyticsEventName.ADD_TO_CART, {
			cartId,
			totalValue,
			products,
		});

	// Set up cookies for Shopify analytics & enable user consent
	useShopifyCookies({
		hasUserConsent: true,
	});

	return {
		sendPageView,
		sendAddToCart,
		pathname,
	};
}
