import imageFragment from "./image";
import seoFragment from "./seo";

const productVariantFragment = /* GraphQL */ `
	fragment product on Product {
		id
		handle
		availableForSale
		title
		description
		descriptionHtml
		productType
		vendor
		options {
			id
			name
			values
		}
		metafields(
			identifiers: [
				{ namespace: "custom", key: "instructor" }
				{ namespace: "custom", key: "slug" }
				{ namespace: "descriptors", key: "subtitle" }
				{ namespace: "custom", key: "orderinseries" }
			]
		) {
			key
			value
		}
		priceRange {
			maxVariantPrice {
				amount
				currencyCode
			}
			minVariantPrice {
				amount
				currencyCode
			}
		}
		variantBySelectedOptions(
			selectedOptions: [{ name: $variantName, value: $variantValue }]
		) {
			id
			title
			availableForSale
			barcode
			compareAtPrice {
				amount
				currencyCode
			}
			price {
				amount
				currencyCode
			}
			sku
			product {
				id
				title
			}
			image {
				url
			}
		}
		variants(first: 250) {
			edges {
				node {
					id
					title
					availableForSale
					sku
					selectedOptions {
						name
						value
					}
					price {
						amount
						currencyCode
					}
					compareAtPrice {
						amount
						currencyCode
					}
				}
			}
		}
		featuredImage {
			...image
		}
		images(first: 20) {
			edges {
				node {
					...image
				}
			}
		}
		collections(first: 10) {
			edges {
				node {
					title
				}
			}
		}
		seo {
			...seo
		}
		tags
		updatedAt
	}
	${imageFragment}
	${seoFragment}
`;

export default productVariantFragment;
