import { Fragment } from "react";
import {
	Popover,
	Transition,
	Menu,
	PopoverPanel,
	PopoverButton,
	PopoverGroup,
} from "@headlessui/react";

import Link from "next/link";
import Image from "next/legacy/image";
import { useCartContext } from "@/context/CartContext";
import { Button } from "./Button";
import AddedItemToCartBanner from "@/components/cart/AddedItemToCartBanner";
import CurrencySelector from "./CurrencySelector";
import {
	AtSign,
	GraduationCap,
	HandHeart,
	House,
	Library,
	MenuIcon,
	MessageCircleQuestion,
	ShoppingBag,
	TvMinimalPlay,
	XIcon,
} from "lucide-react";

const socialNavigation = [
	{
		name: "Facebook",
		href: "https://www.facebook.com/pages/category/Education-Website/Sign-Language-101-373544149412954/",
		icon: (props) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<title>Facebook</title>
				<path
					fillRule="evenodd"
					d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
					clipRule="evenodd"
				/>
			</svg>
		),
		alt: "opens new window with our Facebook page",
	},
	{
		name: "Instagram",
		href: "https://www.instagram.com/sign.language101/",
		icon: (props) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<title>Instagram</title>
				<path
					fillRule="evenodd"
					d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
					clipRule="evenodd"
				/>
			</svg>
		),
		alt: "opens new window with our Instagram page",
	},
	{
		name: "YouTube",
		href: "https://www.youtube.com/@signlanguage1013",
		icon: (props) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<title>YouTube</title>
				<path
					fillRule="evenodd"
					d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
					clipRule="evenodd"
				/>
			</svg>
		),
		alt: "opens new window with our Youtube page",
	},
	{
		name: "TikTok",
		href: "https://www.tiktok.com/@101signlanguage?lang=en",
		icon: (props) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<title>Tik Tok</title>
				<path
					fillRule="evenodd"
					d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"
					clipRule="evenodd"
				/>
			</svg>
		),
		alt: "opens new window with our TikTok page",
	},
];

export const pages = [
	{
		name: "Home",
		description: "Homepage",
		href: "/",
		icon: House,
	},
	{
		name: "Courses",
		description: "Explore our ASL Courses!",
		href: "/courses",
		icon: GraduationCap,
	},
	{
		name: "Books",
		description: "Purchase a printed copy of our course workbook",
		href: "/books",
		icon: Library,
	},
	{
		name: "Free Lessons",
		description: "Learn ASL basics for free!",
		href: "/free-lessons/asl-level-1",
		icon: TvMinimalPlay,
	},
	{
		name: "Gifts",
		description: "Purchase a course as a gift!",
		href: "/gifts",
		icon: HandHeart,
	},
	{
		name: "FAQ",
		description: "Some of our most asked questions!",
		href: "/frequently-asked-questions",
		icon: MessageCircleQuestion,
	},
	{
		name: "Contact Us",
		description: "Let's talk!",
		href: "/contact-us",
		icon: AtSign,
	},
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Nav({ showBackground = true, showCart = true }) {
	const { cart } = useCartContext();
	return (
		<nav role="navigation" className="relative">
			{showCart && <AddedItemToCartBanner />}
			<Popover
				className={classNames(
					showBackground ? "bg-white" : "bg-[#4C5FEB]",
					"relative"
				)}
			>
				<div className="flex justify-between items-center px-4 py-1 sm:px-6 xl:justify-start xl:space-x-10  mx-auto">
					<div className="flex justify-start items-center xl:w-0 xl:flex-1 display:block">
						<Link
							href="/"
							className="flex items-center"
							aria-label="View homepage"
						>
							<span
								className={classNames(
									showBackground ? "text-black" : "text-white",
									"sr-only"
								)}
							>
								Sign Language 101
							</span>
							<Image
								src="/img/brand/sl101-logo.png"
								alt="Sign Language 101 logo"
								width={220}
								height={63}
								priority={true}
							/>
						</Link>
					</div>
					<div className="flex flex-row xl:hidden">
						<div className="flex flex-row ml-4 mr-4 xl:ml-8 relative xl:hidden">
							<div className="mr-2 md:mr-4">
								<CurrencySelector blackText={showBackground} />
							</div>
							{showCart && (
								<Link
									href="/cart"
									className="relative group -m-2 p-2 flex items-center"
								>
									<ShoppingBag
										className={classNames(
											showBackground ? "text-black" : "text-white",
											"flex-shrink-0 h-6 w-6  group-hover:text-gray-500"
										)}
										aria-hidden="true"
									/>
									{cart.totalQuantity > 0 && (
										<span className="ml-2 absolute left-4 mr-2 top-4 text-sm font-medium bg-red-500 text-white rounded-full w-4 h-4">
											<div className="text-center -mt-1">
												{cart.totalQuantity}
											</div>
										</span>
									)}
									<span className="sr-only">items in cart, view bag</span>
								</Link>
							)}
						</div>
						{/* Hamburger Menu */}
						<div className="flex items-center md:ml-4 -mr-2 -my-2 xl:hidden">
							<PopoverButton
								className={classNames(
									showBackground ? " text-black" : "text-white",
									" rounded-md p-2 inline-flex items-center justify-center   focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
								)}
							>
								<span className="sr-only">Open menu</span>
								<MenuIcon className="h-6 w-6" aria-hidden="true" />
							</PopoverButton>
						</div>
					</div>
					{/* Desktop Menu */}
					<PopoverGroup
						className={classNames(
							showBackground
								? "spcae-x-2 xl:space-x-6"
								: "space-x-2 xl:space-x-6",
							"hidden xl:flex "
						)}
					>
						{pages.map((item) => (
							<Link
								href={item.href}
								key={item.name}
								className={classNames(
									showBackground
										? "text-black hover:text-aquamarine-blue-700 "
										: "text-white hover:text-white hover:bg-gray-50  rounded-lg hover:bg-opacity-20 ",
									"font-heading  px-2 py-1"
								)}
							>
								{item.name}
							</Link>
						))}
					</PopoverGroup>
					<div className="hidden xl:flex items-center justify-end xl:flex-1 xl:w-0">
						<CurrencySelector blackText={showBackground} />
						<div className="ml-2 flow-root xl:ml-4 relative">
							{showCart && (
								<Link
									href="/cart"
									className="group -m-2 p-2 flex items-center"
									aria-label="View cart"
								>
									<ShoppingBag
										className={classNames(
											showBackground
												? "text-black group-hover:text-blue-500"
												: "text-white group-hover:text-gray-50",
											"flex-shrink-0 h-6 w-6"
										)}
										aria-hidden="true"
									/>
									{cart.totalQuantity > 0 && (
										<span className="ml-2 absolute left-1 -top-1 text-sm font-medium bg-red-700 text-white rounded-full w-4 h-4">
											<div className="text-center -mt-1">
												{cart.totalQuantity}
											</div>
										</span>
									)}
									<span
										className={classNames(
											showBackground
												? "text-black group-hover:text-blue-500"
												: "text-white group-hover:text-gray-50",
											"sr-only"
										)}
									>
										items in cart, view cart
									</span>
								</Link>
							)}
						</div>
						<Button
							href="https://learning.signlanguage101.com/users/sign_in"
							className={classNames(
								showBackground
									? "bg-blue-600 text-white border-blue-600 hover:bg-blue-800 hover:border-blue-800 hover:text-white"
									: "text-white border-white hover:bg-white hover:text-aquamarine-blue-700 bg-[#4C5FEB]",
								"ml-8 whitespace-nowrap inline-flex items-center justify-center px-8 py-2 border-2  rounded-full shadow-sm text-base font-semibold focus-visible:outline-blue-800"
							)}
						>
							Login
						</Button>
					</div>
				</div>

				<Transition
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<PopoverPanel
						focus
						className=" fixed top-0 inset-x-0  transition transform origin-top-right xl:hidden z-50"
					>
						<div
							className={classNames(
								showBackground ? "bg-blue-600" : " bg-[#5C6CED]",
								"relative shadow-lg  h-screen ring-1 ring-black ring-opacity-5   "
							)}
						>
							<div className="pt-5 pb-6 px-5">
								<div className="flex  justify-between">
									<div>
										<Image
											src="/img/sl101_logo_square.png"
											alt="Sign Language 101 logo"
											width={48}
											height={48}
											className="h-10 w-auto"
										/>
									</div>
									<div className="-mr-2">
										<PopoverButton
											className={classNames(
												showBackground ? "" : "",
												" rounded-md p-2 inline-flex items-center justify-center text-white hover:text-blue-300 focus-visible:outline-white focus:outline-none "
											)}
										>
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</PopoverButton>
									</div>
								</div>
								<div className="mt-6">
									<div className="grid grid-cols-1 gap-y-8">
										{pages.map((page) => {
											return (
												<Link
													href={page.href}
													key={page.name}
													className={
														"-m-3 py-2 flex items-center rounded-lg group"
													}
												>
													<div
														className={classNames(
															showBackground ? "text-white" : "text-white",
															"flex-shrink-0 flex items-center justify-center "
														)}
													>
														<page.icon className="h-6 w-6" aria-hidden="true" />
													</div>
													<div
														className={classNames(
															showBackground ? "text-white" : "text-white ",
															"ml-2 text-lg group-hover:underline group-hover:underline-offset-2 font-medium "
														)}
													>
														{page.name}
													</div>
												</Link>
											);
										})}
										<Link
											href={"/cart"}
											className={"-m-3 py-2 flex items-center rounded-lg group"}
											aria-label="View cart"
										>
											<div
												className={classNames(
													showBackground ? "text-white" : "text-white",
													"flex-shrink-0 flex items-center justify-center"
												)}
											>
												<ShoppingBag className="h-6 w-6" aria-hidden="true" />
											</div>
											<div
												className={classNames(
													showBackground ? "text-white" : "text-white ",
													"ml-2 text-lg group-hover:underline group-hover:underline-offset-2 font-medium  "
												)}
											>
												My Cart
											</div>
										</Link>
									</div>
								</div>
							</div>
							<div className="h-full w-full bg-blue-800 mt-8 py-4 px-5">
								<div className="mt-0 pt-2 pb-6">
									<Button
										variant="solid"
										color="blue"
										href="https://learning.signlanguage101.com/users/sign_in"
										className={"focus-visible:outline-white"}
									>
										Course Login
									</Button>
								</div>
								<div className="px-2 pb-4 pt-4 flex justify-left space-x-6 ">
									{socialNavigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											target="_blank"
											rel="noopener noreferrer"
											alt={item.alt}
											className="text-white hover:text-white"
										>
											<span className="sr-only">{item.name}</span>
											<item.icon
												className="h-6 w-6 text-white hover:text-blue-500"
												aria-hidden="true"
											/>
										</a>
									))}
								</div>
							</div>
						</div>
					</PopoverPanel>
				</Transition>
			</Popover>
		</nav>
	);
}
