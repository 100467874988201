import { useController } from "react-hook-form";
import {
	RadioGroup,
	Label,
	Radio,
	Legend,
	Fieldset,
	Field,
} from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { twMerge } from "tailwind-merge";

export const CustomRadioGroup = (props) => {
	const {
		field: { value, onChange },
	} = useController(props);

	const { options, label } = props;

	return (
		<Fieldset>
			<Legend>{label}</Legend>
			<RadioGroup value={value} onChange={onChange} className="space-y-2">
				<div className="mt-2 flex flex-col gap-y-2">
					{options.map((option) => (
						<Field key={option.label}>
							<Radio
								value={option.value}
								className={({ checked, active }) =>
									twMerge(
										checked ? "border-transparent" : "border-gray-300",
										active ? "border-blue-500 ring-2 ring-blue-500" : "",
										"relative flex cursor-pointer rounded-full border bg-white py-2 px-4 shadow-sm focus:outline-none"
									)
								}
							>
								{({ checked, active }) => (
									<>
										<span className="flex flex-1">
											<span className="flex flex-col">
												<Label
													as="span"
													className="block text-sm font-medium text-gray-900"
												>
													{option.label}
												</Label>
											</span>
										</span>
										<CheckCircleIcon
											className={twMerge(
												!checked ? "invisible" : "",
												"h-5 w-5 text-blue-600"
											)}
											aria-hidden="true"
										/>
										<span
											className={twMerge(
												active ? "border" : "border-2",
												checked ? "border-blue-600" : "border-transparent",
												"pointer-events-none absolute -inset-px rounded-full"
											)}
											aria-hidden="true"
										/>
									</>
								)}
							</Radio>
						</Field>
					))}
				</div>
			</RadioGroup>
		</Fieldset>
	);
};

export default CustomRadioGroup;
