import {
	createContext,
	useContext,
	useReducer,
	useState,
	useEffect,
} from "react";

import axios from "axios";
import { useLogger } from "next-axiom";
import { useCookies } from "react-cookie";

import { useQuery } from "@tanstack/react-query";

const getActiveBanner = (banners, discountCode) => {
	if (banners && banners.length > 0) {
		const primaryBanners = banners.filter((banner) => banner.type == "primary");
		if (primaryBanners.length > 0) return primaryBanners[0];
		return null;
		// if (discountCode) {
		// 	return {
		// 		bg_color: "#17A349",
		// 		collection: "Books",
		// 		message: `🤩 Discount code ${discountCode} applied to cart!`,
		// 		show_link: false,
		// 		text_color: "#ffffff",
		// 	};
		// } else return null;
		// return banners[0];
	} else return null;
};

const fetcher = (url) => axios.get(url).then((res) => res.data);

const Announcements = createContext([]);

const AnnoucementsContext = ({ children }) => {
	const [open, setOpen] = useState(true);
	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["announcements"],
		queryFn: () => fetcher(`/api/sanity/announcements`),
	});
	const [cookies] = useCookies(["discount_code"]);

	return (
		<Announcements.Provider
			value={{
				open,
				setOpen,
				announcements: data,
			}}
		>
			{children}
		</Announcements.Provider>
	);
};

export const useAnnouncementsContext = () => {
	return useContext(Announcements);
};

export default AnnoucementsContext;
