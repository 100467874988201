import { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { Button } from "@/components/common/Button";
import { useLogger } from "next-axiom";

const EmailSubscriptionForm = ({ onComplete }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const [subscriberError, setSubscriberError] = useState("");
	const [loading, setLoading] = useState(false);
	const log = useLogger();

	const handleSubscribe = (data) => {
		setSubscriberError("");
		setLoading(true);
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				const location = response.data;
				axios
					.post("/api/marketing/03-2024/subscribe", {
						email: data.email,
						first_name: data.firstName,
						country: location?.country_name,
						timezone: location?.timezone,
					})
					.then((response) => {
						setLoading(false);
						reset();
						onComplete(response.data?.id);
					})
					.catch((err) => {
						setLoading(false);
						setSubscriberError(
							"Sorry, we're having trouble with our form. Please email us at hello@support.signlanguage101.com for help."
						);
					});
			})
			.catch((error) => {
				log.error("Error fetching geolocation", error);
				axios
					.post("/api/marketing/03-2024/subscribe", {
						email: data.email,
						first_name: data.firstName,
					})
					.then((response) => {
						setLoading(false);
						reset();
						onComplete(response.data?.id);
					})
					.catch((err) => {
						setLoading(false);
						setSubscriberError(
							"Sorry, we're having trouble with our form. Please email us at hello@support.signlanguage101.com for help."
						);
					});
			});
	};

	return (
		<form
			onSubmit={handleSubmit(handleSubscribe)}
			method="POST"
			className="flex flex-col h-full justify-center  flex-wrap  "
			style={{ minHeight: "inherit" }}
		>
			<div className="flex w-full flex-col">
				<p className="text-gray-800 font-medium text-sm mb-6">
					Get the latest updates on our ASL courses, monthly quizzes, free
					language tips and all things related to learning sign language.
				</p>
				<p className="font-heading font-semibold text-gray-900  mb-4">
					Where would you like us to send your free content?
				</p>
				<div className="flex w-full flex-col gap-y-2 gap-x-2 mb-2 md:flex-row">
					<div className="flex flex-col w-full  md:w-2/5">
						<label
							htmlFor="emailListFirstName"
							className="block text-sm font-semibold mb-2 "
						>
							First Name <span className="text-red-500 text-base">*</span>
						</label>
						<input
							type="text"
							id="emailListFirstName"
							placeholder="Name"
							{...register("firstName", {
								required: "Please enter your first name",
								pattern: {
									value: /^[a-z ,.'-]+$/i,
									message: "Please use only letters, spaces, hyphens or commas",
								},
							})}
							aria-required="true"
							aria-invalid={errors.firstName ? "true" : "false"}
							aria-describedby={
								errors.firstName ? "email-list-first-name-error" : undefined
							}
							className="w-full px-3 py-2 placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
						/>
						<ErrorMessage
							errors={errors}
							name="firstName"
							render={({ message }) => (
								<p
									role="alert"
									id="email-list-first-name-error"
									className=" font-medium tracking-wide text-red-500 text-sm mt-1 ml-1"
								>
									{message}
								</p>
							)}
						/>
					</div>
					<div className="flex flex-col w-full  md:w-3/5">
						<label
							htmlFor="emailListEmailAddress"
							className="block text-sm font-semibold mb-2 "
						>
							Email Address <span className="text-red-500 text-base">*</span>
						</label>
						<input
							id="emailListEmailAddress"
							type="email"
							placeholder="name@email.com"
							autoComplete="email"
							{...register("email", {
								required: "Please enter a valid email address",
								pattern: {
									value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
									message: "Please enter a valid email address",
								},
							})}
							aria-invalid={errors.email ? "true" : "false"}
							aria-required="true"
							aria-describedby={
								errors.email ? "email-list-email-error" : undefined
							}
							className="w-full px-3 py-2 placeholder-gray-400 text-sm text-gray-900  outline-none rounded-lg"
						/>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => (
								<p
									role="alert"
									id="email-list-email-error"
									className=" font-medium tracking-wide text-red-500 text-sm mt-1 ml-1"
								>
									{message}
								</p>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="flex w-full justify-end   p-1.5">
				<Button
					variant="solid"
					color="blue"
					type="submit"
					className="lg:w-1/3 px-2 py-2"
					spinner={loading}
				>
					Subscribe
				</Button>
			</div>
			{subscriberError && (
				<p
					role="alert"
					className="text-red-500 text-sm text-center w-full mt-4"
				>
					{subscriberError}
				</p>
			)}
		</form>
	);
};

export default EmailSubscriptionForm;
