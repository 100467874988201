import {
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { useCartContext } from "@/context/CartContext";
import { useLogger } from "next-axiom";

const currencies = [
	{ currencyCode: "USD", countryCode: "US" },
	{ currencyCode: "CAD", countryCode: "CA" },
];

const getCurrencyDescription = (currencyCode) => {
	if (currencyCode === "USD") return "US Dollar";
	else if (currencyCode === "CAD") return "Canadian Dollar";
};

export default function CurrencySelector({ blackText = true }) {
	const log = useLogger();
	const { shopperIdentity, updateShopperIdentity } = useCartContext();

	const handleChange = async (value) => {
		try {
			await updateShopperIdentity(value);
		} catch (err) {
			log.error(err);
		}
	};

	return (
		<Listbox value={shopperIdentity} by="currencyCode" onChange={handleChange}>
			<div className="relative mt-0">
				<ListboxButton
					className={twMerge(
						"relative w-full cursor-default rounded-md  py-1.5 pl-3 pr-10 text-leftfocus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6",
						blackText ? "text-black" : "text-white"
					)}
				>
					<span className="block truncate">{shopperIdentity.currencyCode}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ChevronUpDownIcon
							className={twMerge(
								"h-5 w-5 text-gray-100",
								blackText ? "text-black" : "text-white"
							)}
							aria-hidden="true"
						/>
					</span>
				</ListboxButton>
				<ListboxOptions
					className="absolute font-modals right-2 pb-4 z-10 mt-1 max-h-60  w-full lg:w-[14rem] overflow-hidden rounded-md bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
					anchor="bottom"
				>
					<p className="uppercase text-xs text-gray-600 ml-2 mb-2 mt-2 w-full">
						choose currency
					</p>
					{currencies.map((country) => (
						<ListboxOption
							key={country.currencyCode}
							className="group relative cursor-default select-none "
							value={country}
							aria-selected={
								shopperIdentity.currencyCode === country.currencyCode
							}
						>
							{({ focus, selected }) => (
								<div
									className={twMerge(
										focus && "bg-blue-600 text-white",
										"py-2 pl-3 w-full"
									)}
								>
									<span className="font-semibold  inline truncate w-4 h-4">
										{selected ? (
											<CheckIcon
												className={twMerge(
													"w-4 h-4 text-blue-600 inline-block mr-1.5",
													focus && "text-white"
												)}
											/>
										) : (
											<div className="w-4 h-4 mr-1.5 inline-block"></div>
										)}
										{country.currencyCode}
									</span>
									<span className="ml-4 truncate inline">
										{getCurrencyDescription(country.currencyCode)}
									</span>
								</div>
							)}
						</ListboxOption>
					))}
				</ListboxOptions>
			</div>
		</Listbox>
	);
}
