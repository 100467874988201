import {
	createContext,
	useContext,
	useReducer,
	useState,
	useEffect,
} from "react";
import { cartReducer, initialState } from "./Reducers";
import { termsLastUpdateVersion } from "@/utils/constants";
import axios from "axios";
import { useLogger } from "next-axiom";
import { useCookies } from "react-cookie";

import {
	addToCart,
	createCart,
	getCart,
	removeFromCart,
	updateCart,
	updateCartBuyerIdentity,
	updateCartDiscountCodes,
} from "@/lib/shopify";

import { nanoid } from "nanoid";

import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const Cart = createContext(initialState);

const removeLocalStorage = () => {
	localStorage.removeItem("cid");
};
const getCurrencyCode = (countryCode) => {
	switch (countryCode) {
		case "US":
			return "USD";
		case "CA":
			return "CAD";
		default:
			return "USD";
	}
};

const CartContext = ({ children }) => {
	const log = useLogger();
	const [cookies, setCookie, removeCookie] = useCookies(["cid"]);
	const [cartOpen, setCartOpen] = useState(false);
	const [lastItemAddedToCart, setLastItemAddedToCart] = useState(null);
	const [agreedToTerms, setAgreedToTerms] = useState(false);

	const [state, dispatch] = useReducer(cartReducer, initialState);

	useEffect(() => {
		if (cookies.cid) {
			//checking if there already is a state in localstorage
			//if yes, update the current state with the stored one
			const cartId = cookies.cid;

			getCart(cartId)
				.then((response) => {
					if (response) {
						dispatch({
							type: "INIT_STORED",
							payload: response,
						});
						updateLocalStorage(response);

						//update storefront currency based on cart currency
						if (response.buyerIdentity) {
							updateShopperIdentityOnLoad({
								countryCode: response.buyerIdentity.countryCode,
								currencyCode: getCurrencyCode(
									response.buyerIdentity.countryCode
								),
							});
						}
						//if existing discount codes exist in storage
						if (cookies.discount_code) {
							const activeDiscountCodes = cookies.discount_code.split(",");
							const existingDiscountCodes = response.discountCodes.map((code) =>
								code.code.toUpperCase()
							);
							//and are not already applied to the cart,
							const newDiscountCodes = activeDiscountCodes
								.filter(
									(code) => !existingDiscountCodes.includes(code.toUpperCase())
								)
								.map((code) => code.toUpperCase());
							//add them
							if (newDiscountCodes.length > 0) {
								addDiscountCodes({
									codes: [...existingDiscountCodes, ...newDiscountCodes],
									cartId: response.id,
								}).then((response) => {
									return "completed";
								});
							}
						} else if (
							response.discountCodes &&
							response.discountCodes.length > 0
						) {
							let activeDiscountCodes = response.discountCodes.map(
								(code) => code.code
							);
							setCookie("discount_code", activeDiscountCodes.join(","), {
								path: "/",
							});
						}
					} else {
						clearCart();
					}
				})
				.catch((err) => {
					clearCart();
				});
			// }
		}
	}, []);

	const updateLocalStorage = (payload) => {
		setCookie("cid", payload.id, { path: "/" });
	};

	const numberOfCoursesInCart = () => {
		const courseArr = state.cart.lines.filter(
			(node) =>
				node.merchandise.product.productType == "Course" &&
				(node.merchandise.title == "online course" ||
					node.merchandise.title == "hfcc")
		);
		return courseArr.length;
	};

	const hasCourseInCart = numberOfCoursesInCart() > 0 ? true : false;

	const numberOfItemsInCart = () => {
		let cartQuantity = 0;
		state.cart.lines.forEach((node) => (cartQuantity += node.quantity));
		return cartQuantity;
	};

	const containsItemsNeedingShipping = () => {
		return state.cart.lines.some(
			(line) => line.merchandise.product.productType == "Book"
		);
	};

	const getNonCourseCartItems = () => {
		return state.cart.lines.filter(
			(node) => node.merchandise.product.productType !== "Course"
		);
	};

	const getGiftCourseCartItems = () => {
		return state.cart.lines.filter(
			(node) =>
				node.merchandise.product.productType == "Course" &&
				node.merchandise.title == "gift course"
		);
	};

	const numberOfGiftCoursesInCart = () => {
		return getGiftCourseCartItems().length;
	};

	const hasGiftCourseInCart = numberOfGiftCoursesInCart() > 0 ? true : false;

	const getNewLineItems = ({ intialItems, newItems }) => {
		return newItems.filter(
			({ id: id1, quantity: q1 }) =>
				!intialItems.some(
					({ id: id2, quantity: q2 }) => id2 === id1 && q1 === q2
				)
		);
	};

	const updateShopperIdentity = (shopperId) => {
		return new Promise((resolve, reject) => {
			if (state.cart.id) {
				updateCurrency({ countryCode: shopperId.countryCode })
					.then((response) => {
						dispatch({
							type: "UPDATE_SHOPPER_IDENTITY",
							payload: shopperId,
						});
						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			} else {
				dispatch({
					type: "UPDATE_SHOPPER_IDENTITY",
					payload: shopperId,
				});
				resolve();
			}
		});
	};

	const updateShopperIdentityOnLoad = (shopperId) => {
		dispatch({
			type: "UPDATE_SHOPPER_IDENTITY",
			payload: shopperId,
		});
	};

	const create = ({ items, affRefId = "none" }) => {
		let discountCodes = [];
		if (cookies.discount_code) {
			discountCodes = cookies.discount_code.split(",");
			discountCodes = discountCodes.map((code) => code.toUpperCase());
		}
		return new Promise((resolve, reject) => {
			createCart({
				lineItems: items,
				buyerIdentityInput: { countryCode: state.shopperIdentity.countryCode },
				cartAttributes: [
					{
						key: "checkoutVersion",
						value: "v0923",
					},
					{
						key: "origin",
						value: "sl101",
					},
					{
						key: "termsVersion",
						value: termsLastUpdateVersion,
					},
					{
						key: "affiliateId",
						value: affRefId,
					},
				],
				discountCodes: discountCodes,
			})
				.then((response) => {
					dispatch({
						type: "CREATE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	//NEW
	const addItemToCart = ({
		quantity = 1,
		productVariantId,
		affRefId = "none",
		notifyUser = true,
	}) => {
		return new Promise((resolve, reject) => {
			const initialCartLines = [...state.cart.lines];
			if (state.cart.id) {
				addToCart({
					cartId: state.cart.id,
					lines: [
						{
							quantity: parseInt(quantity),
							merchandiseId: productVariantId,
						},
					],
				})
					.then((response) => {
						if (notifyUser) {
							const newItems = getNewLineItems({
								intialItems: initialCartLines,
								newItems: response.lines,
							});
							setLastItemAddedToCart(
								newItems.length > 0 ? newItems[newItems.length - 1] : null
							);
						}

						dispatch({
							type: "REPLACE_CART",
							payload: response,
						});
						updateLocalStorage(response);

						resolve(response);
					})
					.catch((err) => {
						dispatch({
							type: "CLEAR_CART",
						});
						removeLocalStorage();
						log.error(err);
						reject(err);
					});
			} else {
				create({
					items: [
						{
							quantity: parseInt(quantity),
							merchandiseId: productVariantId,
						},
					],
					affRefId: affRefId,
				})
					.then((response) => {
						if (notifyUser) {
							const newItems = getNewLineItems({
								intialItems: initialCartLines,
								newItems: response.lines,
							});
							setLastItemAddedToCart(
								newItems.length > 0 ? newItems[newItems.length - 1] : null
							);
						}
						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			}
		});
	};

	const createCourseLineItems = (quantity, productVariantId, studentData) => {
		let attributes = [];
		for (let idx = 0; idx < parseInt(quantity); idx++) {
			attributes = [
				...attributes,
				{
					quantity: 1,
					merchandiseId: productVariantId,
					attributes: [
						{
							key: "first",
							value: studentData.firstName,
						},
						{
							key: "last",
							value: studentData.lastName,
						},
						{
							key: "email",
							value: studentData.email,
						},
						{
							key: "studentId",
							value: nanoid().slice(0, 8),
						},
					],
				},
			];
		}

		return attributes;
	};

	//NEW
	const addCourseToCart = ({
		quantity = 1,
		productVariantId,
		studentData = {
			firstName: "*",
			lastName: "*",
			email: "*",
		},
		affRefId = "none",
		notifyUser = true,
	}) => {
		return new Promise((resolve, reject) => {
			const initialCartLines = [...state.cart.lines];

			if (state.cart.id) {
				addToCart({
					cartId: state.cart.id,
					lines: createCourseLineItems(quantity, productVariantId, studentData),
				})
					.then((response) => {
						if (notifyUser) {
							const newItems = getNewLineItems({
								intialItems: initialCartLines,
								newItems: response.lines,
							});

							setLastItemAddedToCart(
								newItems.length > 0 ? newItems[newItems.length - 1] : null
							);
						}

						dispatch({
							type: "REPLACE_CART",
							payload: response,
						});
						updateLocalStorage(response);

						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			} else {
				create({
					items: createCourseLineItems(quantity, productVariantId, studentData),
					affRefId: affRefId,
				})
					.then((response) => {
						if (notifyUser) {
							const newItems = getNewLineItems({
								intialItems: initialCartLines,
								newItems: response.lines,
							});
							setLastItemAddedToCart(
								newItems.length > 0 ? newItems[newItems.length - 1] : null
							);
						}

						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			}
		});
	};

	const createGiftCourseLineItems = (
		quantity,
		productVariantId,
		giftDetails
	) => {
		let attributes = [];
		const excludedKeys = ["giftId"]; // Keys to exclude from the attributes

		for (let idx = 0; idx < parseInt(quantity, 10); idx++) {
			const dynamicAttributes = Object.entries(giftDetails)
				.filter(([key]) => !excludedKeys.includes(key)) // Exclude specified keys
				.map(([key, value]) => ({ key, value })); // Transform into { key, value } objects

			attributes = [
				...attributes,
				{
					quantity: 1,
					merchandiseId: productVariantId,
					attributes: [
						...dynamicAttributes,
						{
							key: "giftId",
							value: nanoid().slice(0, 8), // Add the giftId with a unique value
						},
					],
				},
			];
		}

		return attributes;
	};

	//NEW
	const addGiftCourseToCart = ({
		quantity = 1,
		productVariantId,
		giftDetails = {
			message: "*",
			from: "*",
			email: "*",
			deliver: "Now",
			template: "Everyday",
			method: "email",
		},
		affRefId = "none",
	}) => {
		return new Promise((resolve, reject) => {
			const initialCartLines = [...state.cart.lines];
			if (state.cart.id) {
				addToCart({
					cartId: state.cart.id,
					lines: createGiftCourseLineItems(
						quantity,
						productVariantId,
						giftDetails
					),
				})
					.then((response) => {
						const newItems = getNewLineItems({
							intialItems: initialCartLines,
							newItems: response.lines,
						});

						setLastItemAddedToCart(
							newItems.length > 0 ? newItems[newItems.length - 1] : null
						);

						dispatch({
							type: "REPLACE_CART",
							payload: response,
						});
						updateLocalStorage(response);

						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			} else {
				create({
					items: createGiftCourseLineItems(
						quantity,
						productVariantId,
						giftDetails
					),
					affRefId: affRefId,
				})
					.then((response) => {
						const newItems = getNewLineItems({
							intialItems: initialCartLines,
							newItems: response.lines,
						});

						setLastItemAddedToCart(
							newItems.length > 0 ? newItems[newItems.length - 1] : null
						);

						resolve(response);
					})
					.catch((err) => {
						log.error(err);
						reject(err);
					});
			}
		});
	};

	//NEW
	const removeItemFromCart = ({ itemId }) => {
		return new Promise((resolve, reject) => {
			removeFromCart({ cartId: state.cart.id, lineIds: [itemId] })
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	//NEW
	const updateCartItem = ({ quantity, itemId, productVariantId }) => {
		return new Promise((resolve, reject) => {
			updateCart({
				cartId: state.cart.id,
				lines: [
					{
						quantity: parseInt(quantity),
						id: itemId,
						merchandiseId: productVariantId,
					},
				],
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const updateCourseItem = ({
		quantity,
		itemId,
		productVariantId,
		attributes,
	}) => {
		return new Promise((resolve, reject) => {
			updateCart({
				cartId: state.cart.id,
				lines: [
					{
						quantity: parseInt(quantity),
						id: itemId,
						merchandiseId: productVariantId,
						attributes: attributes,
					},
				],
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const updateGiftCourseItem = ({ lineItemId, merchandiseId, giftDetails }) => {
		return new Promise((resolve, reject) => {
			updateCart({
				cartId: state.cart.id,
				lines: [
					{
						id: lineItemId,
						...createGiftCourseLineItems(1, merchandiseId, giftDetails)[0],
					},
				],
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const addDiscountCode = ({ discountCode }) => {
		return new Promise((resolve, reject) => {
			const existingDiscountCodes = state.cart.discountCodes.map(
				(discount) => discount.code
			);

			const codeAlreadyAdded = state.cart.discountCodes.find(
				(code) => code.code.toUpperCase() === discountCode.toUpperCase()
			);

			if (codeAlreadyAdded) {
				return resolve("Code already added");
			}

			updateCartDiscountCodes({
				cartId: state.cart.id,
				discountCodes: [...existingDiscountCodes, discountCode.toUpperCase()],
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);

					const activeDiscountCodes = response.discountCodes.map(
						(discount) => discount.code
					);

					setCookie("discount_code", activeDiscountCodes.join(","), {
						path: "/",
					});
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const addDiscountCodes = ({ codes, cartId }) => {
		return new Promise((resolve, reject) => {
			updateCartDiscountCodes({
				cartId: cartId,
				discountCodes: codes,
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);

					const activeDiscountCodes = response.discountCodes.map(
						(discount) => discount.code
					);

					setCookie("discount_code", activeDiscountCodes.join(","), {
						path: "/",
					});
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const removeDiscountCode = ({ code }) => {
		const remainingDiscountCodes = state.cart.discountCodes
			.filter((discount) => discount.code !== code)
			.map((discount) => discount.code);
		return new Promise((resolve, reject) => {
			updateCartDiscountCodes({
				cartId: state.cart.id,
				discountCodes: remainingDiscountCodes,
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);

					const activeDiscountCodes = response.discountCodes.map(
						(discount) => discount.code
					);

					setCookie("discount_code", activeDiscountCodes.join(","), {
						path: "/",
					});
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const removeDiscountCodes = () => {
		return new Promise((resolve, reject) => {
			updateCartDiscountCodes({
				cartId: state.cart.id,
				discountCodes: [],
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					removeCookie("discount_code");
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const updateCurrency = ({ countryCode }) => {
		return new Promise((resolve, reject) => {
			updateCartBuyerIdentity({
				cartId: state.cart.id,
				buyerIdentity: {
					countryCode: countryCode,
				},
			})
				.then((response) => {
					dispatch({
						type: "REPLACE_CART",
						payload: response,
					});
					updateLocalStorage(response);
					resolve(response);
				})
				.catch((err) => {
					log.error(err);
					reject(err);
				});
		});
	};

	const validateCart = () => {
		return new Promise((resolve, reject) => {
			axios
				.post(`/api/shopify/v1/storefront/cart/validate-checkout`, {
					cartId: state.cart.id,
				})
				.then((response) => {
					dispatch({
						type: "ADD_ERROR",
						payload: response.data.errors,
					});
					resolve(response);
				})
				.catch((err) => {
					dispatch({
						type: "CLEAR_ERROR",
					});
					reject(err);
				});
		});
	};

	const clearCart = () => {
		dispatch({
			type: "CLEAR_CART",
		});
		removeLocalStorage();
	};

	const clearCartAtCheckout = () => {
		removeLocalStorage();
	};

	return (
		<Cart.Provider
			value={{
				cart: state.cart,
				cartErrors: state.errors,
				dispatch,
				cartOpen,

				create,
				clearCart,
				clearCartAtCheckout,
				addItemToCart,
				removeItemFromCart,
				updateCartItem,
				updateCourseItem,
				addCourseToCart,
				numberOfCoursesInCart,
				updateCurrency,
				addDiscountCode,
				removeDiscountCode,
				removeDiscountCodes,
				addGiftCourseToCart,
				numberOfGiftCoursesInCart,
				numberOfItemsInCart,
				agreedToTerms,
				setAgreedToTerms,
				getNonCourseCartItems,
				getGiftCourseCartItems,
				updateGiftCourseItem,
				hasCourseInCart,
				hasGiftCourseInCart,
				containsItemsNeedingShipping,
				validateCart,
				lastItemAddedToCart,
				setLastItemAddedToCart,
				updateShopperIdentity,
				shopperIdentity: state.shopperIdentity,
			}}
		>
			{children}
		</Cart.Provider>
	);
};

// Add type checking for context consumer
export const useCartContext = () => {
	const context = useContext(Cart);
	if (context === undefined) {
		throw new Error("useCartContext must be used within a CartProvider");
	}
	return context;
};

export default CartContext;
