import productFragment from "../fragments/product";
import productVariantFragment from "../fragments/productVariant";
import productVariantPriceFragment from "../fragments/productVariantPrice";

export const getProductQuery = /* GraphQL */ `
	query getProduct($handle: String!) {
		product(handle: $handle) {
			...product
		}
	}
	${productFragment}
`;
export const getProductById = /* GraphQL */ `
	query getProductById($id: ID!) {
		product(id: $id) {
			...product
		}
	}
	${productFragment}
`;

export const getProductVariantQuery = /* GraphQL */ `
	query getProduct(
		$handle: String!
		$variantName: String!
		$variantValue: String!
		$countryCode: CountryCode!
	) @inContext(country: $countryCode) {
		product(handle: $handle) {
			...product
		}
	}
	${productVariantFragment}
`;

export const getProductVariantPriceQuery = /* GraphQL */ `
	query getProductVariantPrice(
		$handle: String!
		$variantName: String!
		$variantValue: String!
		$countryCode: CountryCode!
	) @inContext(country: $countryCode) {
		product(handle: $handle) {
			...product
		}
	}
	${productVariantPriceFragment}
`;

export const getProductsQuery = /* GraphQL */ `
	query getProducts(
		$sortKey: ProductSortKeys
		$reverse: Boolean
		$query: String
		$countryCode: CountryCode!
	) @inContext(country: $countryCode) {
		products(sortKey: $sortKey, reverse: $reverse, query: $query, first: 100) {
			edges {
				node {
					...product
				}
			}
		}
	}
	${productFragment}
`;

export const getProductRecommendationsQuery = /* GraphQL */ `
	query getProductRecommendations($productId: ID!, $countryCode: CountryCode!)
	@inContext(country: $countryCode) {
		productRecommendations(productId: $productId) {
			...product
		}
	}
	${productFragment}
`;
