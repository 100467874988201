import Image from "next/image";

const getAttribute = (arr, attributeName) => {
	const attributes = arr.filter((attribute) => attribute.key === attributeName);

	return attributes[0].value;
};

const CartItem = ({ item }) => {
	return (
		<li key={item.id} className="relative flex py-4">
			<div className=" w-24 flex-shrink-0 overflow-hidden ">
				<Image
					src={item.merchandise.image.url}
					alt={item.merchandise.product.title}
					width={item.merchandise.image.width}
					height={item.merchandise.image.height}
					className="rounded-md"
				/>
			</div>

			<div className="ml-4 flex flex-1 flex-col">
				<div className="mb-2">
					<div className="flex justify-between text-sm font-medium text-gray-900">
						<div className="flex flex-col">
							<h3 className="font-normal">
								{item?.merchandise?.product?.title}
							</h3>
							<p className="font-normal text-gray-500 text-sm">
								{item?.merchandise?.title}
							</p>
						</div>
						{/* {parseFloat(item.cost.totalAmount.amount) ==
						parseFloat(item.cost.subtotalAmount.amount) ? (
							<p className="ml-4">
								${parseFloat(item.cost.subtotalAmount.amount).toFixed(2)}
							</p>
						) : (
							<p className="ml-4">
								${parseFloat(item.cost.totalAmount.amount).toFixed(2)}{" "}
								<span className="line-through text-gray-500">
									${parseFloat(item.cost.subtotalAmount.amount).toFixed(2)}
								</span>
							</p>
						)} */}
					</div>
				</div>
				<div className="flex flex-1 items-end justify-between text-xs">
					{item?.merchandise?.title == "gift course" ? (
						<p className="text-gray-500">
							Delivery Method: {getAttribute(item.attributes, "method")}
						</p>
					) : (
						// <p>Quantity: {item.quantity}</p>
						<p></p>
					)}
					<div className="flex"></div>
				</div>
			</div>
		</li>
	);
};

export default CartItem;
