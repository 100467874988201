export const klaviyo_identifyUserById = (id) => {
	if (typeof klaviyo !== "undefined") {
		klaviyo.identify({
			id: id,
		});
	}
};

export const klaviyo_identifyUserByEmailAndFirstName = (user) => {
	if (typeof klaviyo !== "undefined") {
		klaviyo.identify({
			email: user.email,
			first_name: user.firstName,
		});
	}
};

export const klaviyo_viewedProduct = (item) => {
	if (typeof klaviyo !== "undefined") {
		const viewedProduct = {
			Name: item.productName,
			ProductID: item.productId.substring(item.productId.lastIndexOf("/") + 1),
			SKU: item.sku,
			Categories: item?.collections
				? item.collections.map((a) => a.title)
				: null,
			ImageURL: item.imageUrl,
			URL: item.url,
			Brand: item.brand,
			Price: item.price,
			CompareAtPrice: item?.compareAtPrice ? item.compareAtPrice : null,
		};

		klaviyo.push(["track", "Viewed Product", viewedProduct]);

		const viewedItem = {
			Title: item.productName,
			ItemId: item.productId.substring(item.productId.lastIndexOf("/") + 1),
			Categories: item?.collections
				? item.collections.map((a) => a.title)
				: null,
			ImageUrl: item.imageUrl,
			Url: item.url,
			Metadata: {
				Brand: item.brand,
				Price: item.price,
				CompareAtPrice: item?.compareAtPrice ? item.compareAtPrice : null,
			},
		};

		klaviyo.push(["trackViewedItem", viewedItem]);
	}
};

const constructProductUrl = (type, handle, variant) => {
	switch (type) {
		case "Course":
			if (variant == "gift course") {
				return `https://www.signlanguage101.com/gifts/${handle}`;
			} else {
				return `https://www.signlanguage101.com/courses/${handle}`;
			}

		case "Book":
			return `https://www.signlanguage101.com/books/${handle}`;
	}
};

const getCartItems = (cartLines) => {
	return cartLines.map((line) => {
		return {
			ProductID: line.merchandise.id.substring(
				line.merchandise.id.lastIndexOf("/") + 1
			),
			SKU: line.merchandise.sku,
			VariantName: line.merchandise.title,
			ProductName: line.merchandise.product.title,
			Quantity: line.quantity,
			ItemPrice: line.merchandise.price.amount,
			RowTotal: line.cost.totalAmount.amount,
			ProductURL: constructProductUrl(
				line.merchandise.product.productType,
				line.merchandise.product.handle,
				line.merchandise.title
			),
			ImageURL: line.merchandise.image.url,
			ProductCategories: line?.merchandise?.product?.collections
				? line.merchandise.product.collections.edges.map((a) => a.node.title)
				: null,
		};
	});
};

export const klaviyo_addedToCart = ({ cart, item, quantity = 1 }) => {
	if (typeof klaviyo !== "undefined") {
		const addedToCartObject = {
			$value: cart.cost.totalAmount.amount,
			total_price: cart.cost.totalAmount.amount,
			original_total_price: cart.cost.subtotalAmount.amount,
			AddedItemProductName: item?.title ? item.title : "",
			AddedItemVariantName: item?.variantBySelectedOptions?.title
				? item.variantBySelectedOptions.title
				: "",
			AddedItemProductID: item.id.substring(item.id.lastIndexOf("/") + 1),
			AddedItemSKU: item.variantBySelectedOptions?.sku
				? item.variantBySelectedOptions.sku
				: null,
			AddedItemCategories: item?.collections
				? item.collections.map((a) => a.title)
				: null,
			AddedItemImageURL: item?.variantBySelectedOptions?.image?.url
				? item.variantBySelectedOptions.image.url
				: null,
			AddedItemURL: constructProductUrl(
				item.productType,
				item.handle,
				item.variantBySelectedOptions.title
			),
			AddedItemPrice: item.variantBySelectedOptions?.price?.amount
				? item.variantBySelectedOptions.price.amount
				: null,
			AddedItemQuantity: quantity,
			CartID: cart.id,
			CheckoutURL: cart.checkoutUrl,
			Items: getCartItems(cart.lines),
		};

		klaviyo.push(["track", "Added to Cart", addedToCartObject]);
	}
};

//The $event_id should be a unique identifier for the cart combined with the UNIX formatted time when the event was triggered. This allows someone to trigger Started Checkout more than once when they return after adding additional items.
// export const klaviyo_startedCheckout = (checkout) => {
// 	if (typeof klaviyo !== "undefined") {
// 		klaviyo.push([
// 			"track",
// 			"Started Checkout",
// 			{
// 				$event_id: "1000123_1387299423",
// 				$value: 29.98,
// 				ItemNames: ["Winnie the Pooh", "A Tale of Two Cities"],
// 				CheckoutURL: "http://www.example.com/path/to/checkout",
// 				Categories: ["Fiction", "Children", "Classics"],
// 				Items: [
// 					{
// 						ProductID: "1111",
// 						SKU: "WINNIEPOOH",
// 						ProductName: "Winnie the Pooh",
// 						Quantity: 1,
// 						ItemPrice: 9.99,
// 						RowTotal: 9.99,
// 						ProductURL: "http://www.example.com/path/to/product",
// 						ImageURL: "http://www.example.com/path/to/product/image.png",
// 						ProductCategories: ["Fiction", "Children"],
// 					},
// 					{
// 						ProductID: "1112",
// 						SKU: "TALEOFTWO",
// 						ProductName: "A Tale of Two Cities",
// 						Quantity: 1,
// 						ItemPrice: 19.99,
// 						RowTotal: 19.99,
// 						ProductURL: "http://www.example.com/path/to/product2",
// 						ImageURL: "http://www.example.com/path/to/product/image2.png",
// 						ProductCategories: ["Fiction", "Classics"],
// 					},
// 				],
// 			},
// 		]);
// 	}
// };

export const klaviyo_removedItemFromCart = (item) => {
	//TODO
};

export const klaviyo_watchedVideo = (item) => {
	if (typeof klaviyo !== "undefined") {
		klaviyo.push([
			"track",
			"Viewed Video",
			{
				Title: item.title,
				ImageURL: item.image,
				Category: item.category,
				VideoURL: item.videoURL,
			},
		]);
	}
};

export const klaviyo_completedLeadGenQuiz = (item) => {
	if (typeof klaviyo !== "undefined") {
		klaviyo.push([
			"track",
			"Completed Quiz",
			{
				QuizTitle: item.title,
				QuizScore: item.score,
				QuizResult: item.result,
				RecommendedProducts: item.recommendedProducts,
				RecommendedFreeLessons: item.recommendedFreeLessons,
				Completed: item.completedAt,
			},
		]);
	}
};

export const isIdentityKnown = async () => {
	if (typeof klaviyo !== "undefined") {
		return await klaviyo.isIdentified();
	} else {
		return false;
	}
};

export const getKlaviyoIdFromCookie = () => {
	// Find the __kla_id cookie
	const cookieValue = document.cookie
		.split("; ")
		.find((row) => row.startsWith("__kla_id="));
	if (!cookieValue) return null;

	// Extract and decode the base64-encoded JSON
	try {
		const base64String = cookieValue.split("=")[1];
		const decodedString = atob(base64String);

		// Parse the JSON and return the $exchange_id if available
		const parsedData = JSON.parse(decodedString);

		return parsedData["$kid"] || null;
	} catch (error) {
		console.error("Failed to decode __kla_id:", error);
		return null;
	}
};
