import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";
import { Button } from "@/components/common/Button";
import { useLogger } from "next-axiom";
import { AnimatePresence, motion } from "framer-motion";
import CustomRadioGroup from "./CustomRadioGroup";

const PreferredContentForm = ({ onComplete, sid }) => {
	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({ defaultValues: { interests: "adults" } });

	const [subscriberError, setSubscriberError] = useState("");
	const [loading, setLoading] = useState(false);
	const log = useLogger();

	const handleSubscribe = (data) => {
		setSubscriberError("");
		setLoading(true);

		axios
			.post("/api/marketing/03-2024/content-preference", {
				id: sid,
				interests: data.interests,
			})
			.then((response) => {
				setLoading(false);
				onComplete();
			})
			.catch((err) => {
				setLoading(false);
				log.error("Error setting user preferred topic", err);
				setSubscriberError(
					"Sorry, we're having trouble with our form. Please email us at hello@support.signlanguage101.com for help."
				);
			});
	};

	const options = [
		{ value: "adults", label: "ASL for Adults (Ages 15+)" },
		{ value: "kids", label: "ASL for Kids" },
		{ value: "both", label: "Both" },
	];

	return (
		<AnimatePresence>
			<motion.div
				initial={{ x: 300, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: -300, opacity: 0 }}
			>
				<form
					onSubmit={handleSubmit(handleSubscribe)}
					method="POST"
					className="flex flex-col h-full w-full items-center justify-center   "
					style={{ minHeight: "inherit" }}
				>
					<div className="flex w-full flex-col">
						<p className="font-heading font-semibold text-gray-900  mb-2">
							Let&apos;s personalize your learning!
						</p>
						<CustomRadioGroup
							name={`interests`}
							options={options}
							label={"What content are you interested in?"}
							control={control}
							required={true}
						/>
					</div>
					<div className="flex w-full justify-end mt-2  p-1.5">
						<Button
							variant="solid"
							color="blue"
							type="submit"
							className="lg:w-1/3 px-2 py-2"
							spinner={loading}
						>
							Continue
						</Button>
					</div>

					{subscriberError && (
						<p
							role="alert"
							className="text-red-500 text-sm text-center w-full mt-4"
						>
							{subscriberError}
						</p>
					)}
				</form>
			</motion.div>
		</AnimatePresence>
	);
};

export default PreferredContentForm;
