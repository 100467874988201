//Kids Course
export const kidsCourseLevel1Title =
	"Sign Language 101 for Kids - Online Course";
export const kidsCourseLevel1Handle = "asl-for-kids-online-course";
export const kidsCourseLevel1ProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTgwNjk2NDk4MTkyMA==";
export const kidsCourseLevel1ProductId = "7276501729440";

export const kidsGiftCourseLevel1Title =
	"Sign Language 101 for Kids - Online Course (Gift)";
export const kidsGiftCourseLevel1Handle = "asl-for-kids-online-course-gift";
export const kidsGiftCourseLevel1ProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTgwNzEwMzA5OTA0MA==";
export const kidsGiftCourseLevel1ProductId = "7276523061408";

export const courseWorkbookTitle =
	"Sign Language 101 for Kids - Course Workbook";
export const courseWorkbookHandle = "sign-language-101-for-kids-workbook";
export const courseWorkbookProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MTg1MTQ4MDM0MjY4OA==";
export const courseWorkbookProductId = "7290863911072";

//Adult Courses
export const level1CourseTitle = "Level 1 - Online ASL Course";
export const level1CourseHandle = "online-asl-course-level-1";
export const level1CourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIwMzk3MTI0NDE5Mg==";
export const level1CourseProductId = "5866262167712";

export const level2CourseTitle = "Level 2 - Online ASL Course";
export const level2CourseHandle = "online-asl-course-level-2";
export const level2CourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIwNDA0OTAzNTQyNA==";
export const level2CourseProductId = "5866271801504";

export const bundledCourseTitle = "Complete Online ASL Course (Levels 1 & 2)";
export const bundledCourseHandle = "bundled-asl-online-course";
export const bundledCourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIxNDM0OTAzMzYzMg==";
export const bundledCourseProductId = "5868370755744";

export const level1GiftCourseTitle = "Level 1 - Online ASL Course (Gift)";
export const level1GiftCourseHandle = "asl-level-1-gift-course";
export const level1GiftCourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzEyMTk1NDc3NTIwMA==";

export const level2GiftCourseTitle = "Level 2 - Online ASL Course (Gift)";
export const level2GiftCourseHandle = "asl-level-2-gift-course";
export const level2GiftCourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIwNDIyODk2NDUxMg==";

export const bundledGiftCourseTitle =
	"Complete Online ASL Course (Levels 1 & 2) (Gift)";
export const bundledGiftCourseHandle = "bundled-asl-online-gift-course";
export const bundledGiftCourseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzIxNDU5NjkyMzU1Mg==";

export const level1GroupPurchaseTitle = "Level 1 - Online ASL Course (GP)";
export const level1GroupPurchaseHandle = "asl-level-1-guided-course-gp";
export const level1CourseGroupPurchaseProductVariantId =
	"Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNjc3Mzc1MzI5MDkxMg==";

//MISC

export const termsLastUpdateDisplayDate = "November 27, 2024";
export const termsLastUpdateVersion = "11272024";

export const newBundledCourseId = "gid://shopify/ProductVariant/43994418348192";
