import { useState, useEffect } from "react";
import { useLogger } from "next-axiom";
import EmailSubscriptionForm from "../marketing/EmailSubscriptionForm";
import UserGoalsForm from "../marketing/UserGoalsForm";
import {
	klaviyo_identifyUserByEmailAndFirstName,
	klaviyo_identifyUserById,
} from "@/utils/klaviyo-analytics";
import PreferredContentForm from "../marketing/PreferredContentForm";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const EmailListSignupForm = ({
	heading = "Sign up for our email list",
	centerHeading = false,
}) => {
	const log = useLogger();
	const [subscriberId, setSubscriberId] = useState(null);

	const handleSubscribed = (id) => {
		setCurrentStep(1);
		klaviyo_identifyUserById(id);
		setSubscriberId(id);
	};

	const handleNextStep = () => {
		setCurrentStep((prev) => prev + 1);
	};

	const formSteps = [
		{
			stepName: "subscription",
			form: <EmailSubscriptionForm onComplete={handleSubscribed} />,
		},
		{
			stepName: "preferredContent",
			form: (
				<PreferredContentForm onComplete={handleNextStep} sid={subscriberId} />
			),
		},
		// {
		// 	stepName: "userGoals",
		// 	form: <UserGoalsForm onComplete={handleNextStep} sid={subscriberId} />,
		// },
		{
			stepName: "Confirmation",
			form: (
				<AnimatePresence>
					<motion.div
						initial={{ x: 300, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: -300, opacity: 0 }}
					>
						<div
							className="flex flex-col h-full justify-between  flex-wrap "
							style={{ minHeight: "inherit" }}
						>
							<div>
								<p className="text-4xl text-center">🎉</p>
								<p className="text-center font-heading font-semibold text-gray-900  mb-4">
									Congrats, you&apos;ve been added to the list!
								</p>
								<p className="text-sm">
									Please check your inbox for a confirmation email and click the
									link to complete your subscription. If you don&apos;t see the
									email, please check your spam folder.
								</p>
							</div>
							<div className="flex w-full justify-center py-2 mt-2 md:py-0 md:justify-end">
								<button
									onClick={() => {
										setCurrentStep(0);
										setSubscriberId(null);
									}}
									className="text-gray-700 text-center text-sm underline hover:text-blue-900"
								>
									Return to start
								</button>
							</div>
						</div>
					</motion.div>
				</AnimatePresence>
			),
		},
	];

	const [currentStep, setCurrentStep] = useState(0);

	return (
		<div className="flex w-full flex-col justify-between">
			<div className="text-left ">
				<h3
					className={twMerge(
						" font-heading font-semibold text-gray-900 text-lg",
						centerHeading && "text-center"
					)}
				>
					{heading}
				</h3>
			</div>
			<div className="flex w-full flex-col overflow-hidden  min-h-56 justify-center   pb-2  mt-4">
				{formSteps[currentStep].form}
			</div>
			<p className="text-gray-500 text-xs text-center mt-2 ">
				By subscribing, you agree to receive marketing emails from us.
				Don&apos;t worry, we promise not to spam you! You can unsubscribe
				anytime.
			</p>
		</div>
	);
};

export default EmailListSignupForm;
