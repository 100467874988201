export const config = {
	api: {
		bodyParser: false,
	},
};

export const getSimpleProductId = (id) => {
	if (id.includes("gid://shopify/Product/")) {
		return id.replace("gid://shopify/Product/", "");
	} else return id;
};

export const getSimpleProductVariantId = (id) => {
	if (id.includes("gid://shopify/ProductVariant/")) {
		return id.replace("gid://shopify/ProductVariant/", "");
	} else return id;
};

export const shopifyCartIdPrefix = "gid://shopify/Cart/";

export const getSimpleCartId = (id) => {
	if (id.includes(shopifyCartIdPrefix)) {
		return id.replace(shopifyCartIdPrefix, "");
	} else return id;
};

export const getMetaDataForKey = ({ keyName, metaData }) => {
	const filteredSet = metaData.filter((item) => {
		return item?.key == keyName;
	});
	if (filteredSet.length > 0) {
		return filteredSet[0].value;
	} else {
		return "";
	}
};

const calculateSavings = (originalPrice, discountedPrice) => {
	return Math.round(100 - (discountedPrice / originalPrice) * 100);
};

export const getDiscountedText = ({ price, compareAtPrice }) => {
	const priceDifference = compareAtPrice - price;
	const savings = calculateSavings(compareAtPrice, price);

	if (priceDifference > savings) {
		return "Save $" + priceDifference.toFixed(2);
	} else {
		return `Save ${calculateSavings(compareAtPrice, price)}%`;
	}
};

export const getCurrencySymbol = (currencyCode) => {
	switch (currencyCode) {
		case "USD":
		case "US":
			return "";

		case "CAD":
		case "CA":
			return " CAD";
		default:
			return "";
	}
};
