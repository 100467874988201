export const books = [
	{
		id: "2",
		handle: "american-sign-language-dictionary-for-beginners",
		title:
			"American Sign Language Dictionary for Beginners: A Visual Guide with 800+ ASL Signs",
		author: "Tara Adams",
		metaDescription:
			"Learn over 800 essential ASL words with clear directions, making it easy for beginners to start their ASL journey confidently and effectively.",
		description:
			"<p>Whatever your reason for learning the richly expressive language of American Sign Language (ASL), this book will guide you through the initial stages of your signing journey. It’s filled with everything you need to master more than 800 essential vocabulary words, including detailed directions that make it simple to develop your ASL skills.</p>",
		publisher: "Rockridge Press",
		language: "English",
		format: "paperback",
		pages: 334,
		isbn: "1685397131",
		shopifyProduct: false,
		weight: "1.7 pounds",
		dimensions: "7.5 x 1 x 9.25 inches",
		images: [
			{
				url: "/img/book/american-sign-language-dictionary.jpeg",
				width: 2076,
				height: 2560,
				featuredImage: true,
				alt: "American Sign Language Dictionary for Beginners",
			},
		],
		price: { amount: "21.99", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/American-Sign-Language-Dictionary-Beginners/dp/1685397131?_encoding=UTF8&qid=&sr=&linkCode=ll1&tag=sl10106-20&linkId=5c021e7d0ed763a992ec939519984ec2&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "3",
		handle: "baby-sign-language-made-easy",
		title:
			"Baby Sign Language made easy - 101 signs to start communicating with your child now",
		author: "Lane Rebelo",
		metaDescription:
			"Teach your baby 101 essential ASL signs to communicate their needs, from mealtime to emotions. Simple tips make learning and bonding easy for ages 0–3.",
		description:
			"<p>Discover the easy way to teach sign language to babies and toddlers ages 0 to 3 with this guide.</p><br /><p>Sign language is a fun and effective way for babies to communicate without words while developing essential early learning skills but we understanding knowing where to start can be a challenge. This book of baby sign language makes it easy to help your child learn, with 101 useful, everyday ASL signs and a clear, simple format that explains what signs are best to start with and tips for teaching them. Whether it’s asking for milk or telling you that they’re tired, you’ll learn how to give your baby the tools to tell you what they’re thinking.</p><br /><ul class='list-disc list-inside'><li> BABY SIGN LANGUAGE BASICS: Get an overview of what baby sign language is and how it can help you and your child connect, as well as how it can aid in their development.</li><li> HOW TO TEACH SIGNS: Find guidance for getting started, capturing your baby’s attention, and when to add more signs based on their age and progress.</li><li> EASY ORGANIZATION: Each chapter is organized by topics like mealtime, getting dressed, playtime, animals, and feelings, so it’s quick to find the signs you are looking for.</li></ul><br /><p> Start communicating early with the baby sign language book that makes it simple.</p>",
		publisher: "Rockridge Press",
		language: "English",
		format: "paperback",
		pages: 168,
		isbn: "1641520779",
		shopifyProduct: false,
		weight: "1 pounds",
		dimensions: "8.5 x 0.5 x 8.8 inches",
		images: [
			{
				url: "/img/book/baby-sign-language-made-easy.jpeg",
				width: 2418,
				height: 2560,
				featuredImage: true,
				alt: "Baby Sign Language made easy",
			},
		],
		price: { amount: "14.99", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Baby-Sign-Language-Made-Easy/dp/1641520779?_encoding=UTF8&qid=&sr=&linkCode=ll1&tag=sl10106-20&linkId=3ff5429ffc0565a2fa2baa66936fd667&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "4",
		handle: "deaf-utopia",
		title: "Deaf Utopia: A Memoir - and a Love Letter to a Way of Life",
		author: "Nyle Dimarco, Robert Siebert",
		metaDescription:
			"Join Nyle DiMarco's journey from Deaf childhood to reality TV stardom. A heartfelt celebration of ASL and the vibrant Deaf community.",
		description:
			"<p>A heartfelt and inspiring memoir and celebration of Deaf culture by Nyle DiMarco, actor, producer, two-time reality show winner, and cultural icon of the international Deaf community<br /><p>Before becoming the actor, producer, advocate, and model that people know today, Nyle DiMarco was half of a pair of Deaf twins born to a multi-generational Deaf family in Queens, New York. At the hospital one day after he was born, Nyle “failed” his first test—a hearing test—to the joy and excitement of his parents.</p><br /><p>In this engrossing memoir, Nyle shares stories, both heartbreaking and humorous, of what it means to navigate a world built for hearing people. From growing up in a rough-and-tumble childhood in Queens with his big and loving Italian-American family to where he is now, Nyle has always been driven to explore beyond the boundaries given him. A college math major and athlete at Gallaudet—the famed university for the Deaf in Washington, DC—Nyle was drawn as a young man to acting, and dove headfirst into the reality show competitions America’s Next Top Model and Dancing with the Stars—ultimately winning both competitions.</p><br /><p>Deaf Utopia is more than a memoir, it is a cultural anthem—a proud and defiant song of Deaf culture and a love letter to American Sign Language, Nyle’s featuredImage language. Through his stories and those of his Deaf brothers, parents, and grandparents, Nyle opens many windows into the Deaf experience.</p><br /><p>Deaf Utopia is intimate, suspenseful, hilarious, eye-opening, and smart—both a memoir and a celebration of what makes Deaf culture unique and beautiful.</p>",
		publisher: "William Morrow",
		language: "English",
		format: "hardcover",
		pages: 336,
		isbn: "0063062356",
		shopifyProduct: false,
		weight: "1.25 pounds",
		dimensions: "6 x 1.09 x 9 inches",
		images: [
			{
				url: "/img/book/deaf-utopia.jpg",
				width: 315,
				height: 475,
				featuredImage: true,
				alt: "Deaf Utopia",
			},
		],
		price: { amount: "27.99", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Deaf-Utopia-Memoir_and-Love-Letter/dp/0063062356?_encoding=UTF8&qid=1660674706&sr=1-1&linkCode=ll1&tag=sl10106-20&linkId=c3f0f8cc25798cc99da3e158619d3d93&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "5",
		handle: "everyone-here-spoke-sign-language",
		title:
			"Everyone Here Spoke Sign Language: Hereditary Deafness on Martha's Vineyard",
		author: "Nora Ellen Groce",
		metaDescription:
			"Discover the fascinating story of Martha's Vineyard's unique Deaf community, where sign language created a fully integrated society without barriers.",
		description:
			"<p>From the seventeenth century to the early years of the twentieth, the population of Martha’s Vineyard manifested an extremely high rate of profound hereditary deafness. In stark contrast to the experience of most Deaf people in our own society, the Vineyarders who were born Deaf were so thoroughly integrated into the daily life of the community that they were not seen—and did not see themselves—as handicapped or as a group apart. Deaf people were included in all aspects of life, such as town politics, jobs, church affairs, and social life. How was this possible?</p><br/><p>On the Vineyard, hearing and Deaf islanders alike grew up speaking sign language. This unique sociolinguistic adaptation meant that the usual barriers to communication between the hearing and the Deaf, which so isolate many Deaf people today, did not exist.</p>",
		publisher: "Harvard University Press",
		language: "English",
		format: "paperback",
		pages: 169,
		isbn: "067427041X",
		shopifyProduct: false,
		weight: "7.2 ounces",
		dimensions: "6 x 9 inches",
		images: [
			{
				url: "/img/book/everyone-here-spoke-sign-language.jpeg",
				width: 1200,
				height: 1837,
				featuredImage: true,
				alt: "Everyone Here Spoke Sign Language",
			},
		],
		price: { amount: "29.0", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Everyone-Here-Spoke-Sign-Language/dp/067427041X?crid=2W6FAOC3I39L9&keywords=everyone+here+spoke+sign+language&qid=1660675389&s=books&sprefix=everyone+here+spoke+sign+language%2Cstripbooks%2C387&sr=1-1&linkCode=ll1&tag=sl10106-20&linkId=08454913c86870c07233de144f38d3e4&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "1",
		handle: "a-place-of-their-own",
		title: "A place of their own: Creating the Deaf Community in America",
		author: "John Vickrey Van Cleave, Barry A. Crouch",
		metaDescription:
			"Discover how 19th-century Deaf Americans built their community and language, creating a rich cultural heritage through education.",
		description:
			"<p>Using original sources, this unique book focuses on the Deaf community during the nineteenth century. Largely through schools for the deaf, deaf people began to develop a common language and a sense of community. A Place of Their Own brings the perspective of history to bear on the reality of deafness and provides fresh and important insight into the lives of Deaf Americans.</p>",
		publisher: "Gallaudet University Press; 1st edition (March 10, 1989)",
		language: "English",
		format: "paperback",
		pages: 212,
		isbn: "0930323491",
		shopifyProduct: false,
		weight: "11.9 ounces",
		dimensions: "6 x 0.5 x 9 inches",
		images: [
			{
				url: "/img/book/a-place-of-their-own.jpeg",
				width: 800,
				height: 1200,
				featuredImage: true,
				alt: "A Place of Their Own",
			},
		],
		price: { amount: "22.95", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Place-Their-Own-Creating-Community/dp/0930323491?keywords=A+Place+of+Their+Own%3A+Creating+the+Deaf+Community+in+America&qid=1554596954&s=gateway&sr=8-1-fkmrnull&linkCode=ll1&tag=sl10106-20&linkId=359ecf4b156cb55781dd0088d97cde15&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "6",
		handle: "forbidden-signs",
		title:
			"Forbidden Signs: American Culture and the Campaign against Sign Language",
		author: "Douglas C. Baynton",
		metaDescription:
			"Explore the historical struggle over ASL rights and Deaf education. Uncover how language shaped identity and power in 19th-century America.",
		description:
			"<p>Forbidden Signs explores American culture from the mid-nineteenth century to 1920 through the lens of one striking episode: the campaign led by Alexander Graham Bell and other prominent Americans to suppress the use of sign language among deaf people.</p><br/><p>The ensuing debate over sign language invoked such fundamental questions as what distinguished Americans from non-Americans, civilized people from 'savages,' humans from animals, men from women, the natural from the unnatural, and the normal from the abnormal. An advocate of the return to sign language, Baynton found that although the grounds of the debate have shifted, educators still base decisions on many of the same metaphors and images that led to the misguided efforts to eradicate sign language.</p><br/><p>'Baynton's brilliant and detailed history, Forbidden Signs, reminds us that debates over the use of dialects or languages are really the linguistic tip of a mostly submerged argument about power, social control, nationalism, who has the right to speak and who has the right to control modes of speech.'—Lennard J. Davis, The Nation</p><br/><p>'Forbidden Signs is replete with good things.'—Hugh Kenner, New York Times Book Review</p>",
		publisher: "University of Chicago Press",
		language: "English",
		format: "paperback",
		pages: 235,
		isbn: "0226039641",
		shopifyProduct: false,
		weight: "13.8 ounces",
		dimensions: "5.98 x 0.62 x 8.98 inches",
		images: [
			{
				url: "/img/book/forbidden-signs.jpeg",
				width: 907,
				height: 1360,
				featuredImage: true,
				alt: "Forbidden Signs",
			},
			{
				url: "/img/book/forbidden-signs-2.jpeg",
				width: 907,
				height: 1360,
				featuredImage: false,
				alt: "Forbidden Signs back",
			},
		],
		price: { amount: "28.0", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Forbidden-Signs-American-Campaign-Language/dp/0226039641?crid=23117MF29QR94&keywords=Forbidden+Signs%3A+American+Culture+and+the+Campaign+against+Sign+Language&qid=1660688053&sprefix=forbidden+signs+american+culture+and+the+campaign+against+sign+language%2Caps%2C364&sr=8-1-fkmr0&linkCode=ll1&tag=sl10106-20&linkId=39d8b45f31dc958d501d35d3b77acdfb&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "7",
		handle: "deaf-in-america",
		title: "Deaf in America: Voices from a Culture",
		author: "Carol A. Padden, Tom L. Humphries",
		metaDescription:
			"Explore Deaf culture through compelling stories, art, and history. Discover how language shapes identity in the Deaf community.",
		description:
			"<p>Written by authors who are themselves Deaf, this unique book illuminates the life and culture of Deaf people from the inside, through their everyday talk, their shared myths, their art and performances, and the lessons they teach one another. Carol Padden and Tom Humphries employ the capitalized 'Deaf' to refer to deaf people who share a natural language―American Sign Language (ASL―and a complex culture, historically created and actively transmitted across generations.</p><br/><p>Signed languages have traditionally been considered to be simply sets of gestures rather than natural languages. This mistaken belief, fostered by hearing people’s cultural views, has had tragic consequences for the education of deaf children; generations of children have attended schools in which they were forbidden to use a signed language. For Deaf people, as Padden and Humphries make clear, their signed language is life-giving, and is at the center of a rich cultural heritage.</p><br/><p><The tension between Deaf people’s views of themselves and the way the hearing world views them finds its way into their stories, which include tales about their origins and the characteristics they consider necessary for their existence and survival. Deaf in America includes folktales, accounts of old home movies, jokes, reminiscences, and translations of signed poems and modern signed performances. The authors introduce new material that has never before been published and also offer translations that capture as closely as possible the richness of the original material in ASL.</p><br/><p>Deaf in America will be of great interest to those interested in culture and language as well as to Deaf people and those who work with deaf children and Deaf people.</p>",
		publisher: "Harvard University Press",
		language: "English",
		format: "paperback",
		pages: 144,
		isbn: "0674194233",
		shopifyProduct: false,
		weight: "14.1 ounces",
		dimensions: "6.02 x 0.59 x 10.94 inches",
		images: [
			{
				url: "/img/book/deaf-in-america.jpeg",
				width: 1200,
				height: 1880,
				featuredImage: true,
				alt: "Deaf in America",
			},
		],
		price: { amount: "30.0", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Deaf-America-Culture-Carol-Padden/dp/0674194241?_encoding=UTF8&qid=1660688849&sr=8-1&linkCode=ll1&tag=sl10106-20&linkId=ace08624198306bc69056bd401c0ba38&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "8",
		handle: "american-sign-language-phrase-book",
		title: "The American Sign Language Phrase Book",
		author: "Barbara Berstein Fant",
		metaDescription:
			"Unlock ASL communication with over 500 phrases for daily interactions, from family and health to tech. Perfect for beginners seeking real-world ASL fluency.",
		description:
			"<p>Open up a whole new world of communication through ASL</p><br /><p>You can easily learn ASL with help from The American Sign Language Phrase Book. With more than 500 phrases, this is the reference guide to everyday expressions in American Sign Language, providing a quick way for you to converse with deaf people.</p><br /><p>Clearly illustrated with hundreds of line drawings, this book focuses on areas such as health, family, school, sports, travel, religion, time, money, food and drink, and small talk. This edition's new chapter on technology boasts 50 phrases to help you communicate about the Internet, computing, video relay, and more. There is even a chapter that gives you phrases for communicating about signing: asking people to sign slower, indicating your fingerspelling ability, and requesting help with your fledgling skills.</p><br /><p>From asking simple questions (“How are you?”) to more complex phrases (“There's no sign for that, you have to fingerspell it.”), The American Sign Language Phrase Book gives you the power to communicate easily and comfortably in ASL.</p>",
		publisher: "McGraw Hill",
		language: "English",
		format: "paperback",
		pages: 400,
		isbn: "0071497137",
		shopifyProduct: false,
		weight: "1.15 pounds",
		dimensions: "6 x 0.7 x 9 inches",
		images: [
			{
				url: "/img/book/asl-phrase-book.jpeg",
				width: 331,
				height: 500,
				featuredImage: true,
				alt: "American Sign Language Phrasebook",
			},
		],
		price: { amount: "28.0", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/American-Sign-Language-Phrase-Book/dp/0071497137?_encoding=UTF8&qid=1660690018&sr=1-1&linkCode=ll1&tag=sl10106-20&linkId=ef51908e112016d4395789e45fad2d47&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "9",
		handle: "dad-and-me-in-the-morning",
		title: "Dad and Me in the Morning",
		author: "Patricia Lakin, Robert G. Steele",
		metaDescription:
			"A boy and his father share a quiet morning walk to the beach, discovering the beauty of wordless communication and nature in this heartfelt story.",
		description:
			"<p>Early one morning, a young boy wakes to the light of his alarm clock. He puts on his hearing aids and clothes, then goes to wake his father. Together they brave the cold as they walk down the dirt road that leads to the beach. Lakin's understated story reminds readers that sometimes the best way to communicate doesn't involve words, while Steele’s watercolor illustrations show that beauty is never far away.</p>",
		publisher: "Albert Whitman & Company",
		language: "English",
		format: "paperback",
		pages: 32,
		isbn: "0807514209",
		shopifyProduct: false,
		weight: "4 ounces",
		dimensions: "8 x 0.1 x 10 inches",
		images: [
			{
				url: "/img/book/dad-and-me-in-the-morning.jpeg",
				width: 801,
				height: 1000,
				featuredImage: true,
				alt: "Dad and Me in the Morning",
			},
		],
		price: { amount: "7.99", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/Dad-Me-Morning-Patricia-Lakin/dp/0807514209?&linkCode=ll1&tag=sl10106-20&linkId=0c7847fd00f06fd917c71e297643d53a&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "10",
		handle: "true-biz",
		title: "True Biz: A Novel",
		author: "Sarah Novic",
		metaDescription:
			"Experience life at a Deaf boarding school in this powerful novel about love, identity, and resilience. A moving story of human connection.",
		description:
			"<p>NEW YORK TIMES BESTSELLER • REESE’S BOOK CLUB PICK • A “tender, beautiful and radiantly outraged” (The New York Times Book Review) novel that follows a year of seismic romantic, political, and familial shifts for a teacher and her students at a boarding school for the deaf, from the acclaimed author of Girl at War</p><br/><p>'For those who loved the Oscar-winning film CODA, a boarding school for deaf students is the setting for a kaleidoscope of experiences.'—The Washington Post</p><br/><p>ONE OF THE MOST ANTICIPATED BOOKS OF 2022—Oprah Daily, The Millions, Lit Hub, Publishers Weekly, BookPage</p><br/><p>True biz (adj./exclamation; American Sign Language): really, seriously, definitely, real-talk</p><br/><p>True biz? The students at the River Valley School for the Deaf just want to hook up, pass their history finals, and have politicians, doctors, and their parents stop telling them what to do with their bodies. This revelatory novel plunges readers into the halls of a residential school for the deaf, where they’ll meet Charlie, a rebellious transfer student who’s never met another deaf person before; Austin, the school’s golden boy, whose world is rocked when his baby sister is born hearing; and February, the hearing headmistress, a CODA (child of deaf adult(s)) who is fighting to keep her school open and her marriage intact, but might not be able to do both. As a series of crises both personal and political threaten to unravel each of them, Charlie, Austin, and February find their lives inextricable from one another—and changed forever.</p><br/><p>This is a story of sign language and lip-reading, disability and civil rights, isolation and injustice, first love and loss, and, above all, great persistence, daring, and joy. Absorbing and assured, idiosyncratic and relatable, this is an unforgettable journey into the Deaf community and a universal celebration of human connection.</p>",
		publisher: "Random House",
		language: "English",
		format: "hardcover",
		pages: 400,
		isbn: "0593241509",
		weight: "1.45 pounds",
		dimensions: "6.64 x 1.23 x 9.56 inches",
		images: [
			{
				url: "/img/book/true-biz.jpeg",
				width: 498,
				height: 597,
				featuredImage: true,
				alt: "True Biz: A Novel",
			},
		],
		price: { amount: "28.0", currencyCode: "USD" },
		externalLink:
			"https://www.amazon.com/True-Biz-Novel-Sara-Novic/dp/0593241509?&linkCode=ll1&tag=sl10106-20&linkId=656a4806409d8647142ebef1f5646c3d&language=en_US&ref_=as_li_ss_tl",
	},
	{
		id: "10",
		handle: "getting-started-with-asl-course-workbook",
		title: "Getting Started with ASL - Course Workbook",
		author: "Sign Language 101",
		metaDescription:
			"Enhance your ASL skills with the Getting Started Workbook for beginners. It includes exercises on vocabulary, numbers, grammar, and insights into Deaf culture.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 97,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "1 pound",
		dimensions: "8.5 x 11 inches",
		images: [
			{
				url: "/img/book/getting-started-with-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Getting Started with ASL Course Workbook",
			},
			{
				id: 3,
				url: "/img/book/level-1-interior.png",
				alt: "Photo of interior of workbook",
				featuredImage: false,
				width: "600",
				height: "821",
			},
		],
		price: { amount: "22.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "101 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "1 pound" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		stats: {
			weight: "1 pound",
			dimensions: "8.5 x 11 inches",
			format: "Spiral bound",
			pages: 101,
			publisher: "Sign Language 101",
			language: "English",
		},
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		relatedProducts: [
			{
				id: 1,
				name: "ASL Level 1A - Online Course",
				url: "/courses/level-1a-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 1,
				name: "ASL Level 1B - Online Course",
				url: "/courses/level-1b-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 2,
				name: "Complete Online ASL Online Course",
				url: "/courses/complete-course-bundle",
				imageUrl: "/img/course/complete/complete-mock.png",
				imageAlt: "ASL Level 2 - Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/level1a/level-1a-spiral-cover.png",
					alt: "Getting Started with ASL Course Workbook",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/level1a/browser-book-mockup-level-1a.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"Dive into fun, interactive exercises after each lesson that lock in key concepts and turn them into confident signing skills.",
					},
					{
						title: "📘 Pro Tips for Success:",
						content:
							"Get insider advice throughout the workbook that feels like having your own ASL tutor. You’ll be signing like a pro in no time!",
					},
					{
						title: "🎯 Extended Learning:",
						content:
							"At the end of each chapter, tackle enrichment activities that stretch your understanding and make mastering ASL both fun and effective.",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/level1a/level-1a-activities.png",
					alt: "ASL workbook interior pages with vocabulary lists",
					width: 676,
					height: 517,
				},
				title: "Boost Your Vocabulary & Nail Those Numbers",
				paragraphs: [
					"Stay on top of your ASL journey with easy-to-reference vocabulary lists for all the signs and numbers you’ll master in the course. Our workbook is filled with fun practice activities to help you truly learn, remember, and confidently use every new sign.",
					"And there's more! Exclusive 'Signing Like a Pro' sections give you insider tips and tricks you won’t find in the online course, helping you sharpen your skills and elevate your fluency to the next level.",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/level1a/level-1a-instruction.png",
					alt: "Level 1A workbook pages with grammar instruction",
					width: 676,
					height: 517,
				},
				title: "Reinforce Your Learning",
				paragraphs: [
					"Unlock the key to mastering ASL with clear, engaging grammar lessons that make complex concepts easy to grasp. Our workbook is packed with fun, sentence-building exercises that directly connect to your video lessons, giving you the perfect practice for applying what you’ve learned.",
					"Plus, you’ll sharpen your ability to ask and answer questions—an essential skill for confident, real-world conversations. With every page, you’ll feel more fluent and ready to sign like a pro!",
					"Designed to elevate your signing abilities, this workbook is your step-by-step guide to ASL success!",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/level1a/level-1a-workbook-open.png",
					alt: "Interior of Getting Started with ASL Course Workbook",
					width: 800,
					height: 524,
				},
				sampleUrl: "/files/SignLanguage101_Level_1A_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/level1a/level-1a-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/SignLanguage101_Level_1A_Sample_Chapter.pdf",
	},
	{
		id: "11",
		handle: "building-on-the-basics-of-asl-course-workbook",
		title: "Building on the Basics of ASL - Course Workbook",
		author: "Sign Language 101",
		metaDescription:
			"Enhance your ASL skills with our intermediate workbook. Practice grammar and vocabulary while deepening your understanding of Deaf culture.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 117,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "1 pound",
		dimensions: "8.5 x 11 inches",
		images: [
			{
				url: "/img/book/building-on-the-basics-of-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Building on the Basics of ASL Course Workbook",
			},
			{
				id: 3,
				url: "/img/book/level-1-interior.png",
				alt: "Photo of interior of workbook",
				featuredImage: false,
				width: "600",
				height: "821",
			},
		],
		price: { amount: "22.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "121 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "1 pound" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		relatedProducts: [
			{
				id: 1,
				name: "ASL Level 1A - Online Course",
				url: "/courses/level-1a-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 1,
				name: "ASL Level 1B - Online Course",
				url: "/courses/level-1b-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 2,
				name: "Complete Online ASL Online Course",
				url: "/courses/complete-course-bundle",
				imageUrl: "/img/course/complete/complete-mock.png",
				imageAlt: "ASL Level 2 - Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/level1b/level-1b-spiral-cover.png",
					alt: "Building on the Basics of ASL Course Workbook",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/level1b/browser-book-mockup-level-1b.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"Dive into fun, interactive exercises after each lesson that lock in key concepts and turn them into confident signing skills.",
					},
					{
						title: "📘 Pro Tips for Success:",
						content:
							"Get insider advice throughout the workbook that feels like having your own ASL tutor. You’ll be signing like a pro in no time!",
					},
					{
						title: "🎯 Extended Learning:",
						content:
							"At the end of each chapter, tackle enrichment activities that stretch your understanding and make mastering ASL both fun and effective.",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/level1b/level-1b-activities.png",
					alt: "ASL workbook interior pages with activities",
					width: 800,
					height: 600,
				},
				title: "Boost Your Vocabulary & Nail Those Numbers",
				paragraphs: [
					"Stay on top of your ASL journey with easy-to-reference vocabulary lists for all the signs and numbers you’ll master in the course. Our workbook is filled with fun practice activities to help you truly learn, remember, and confidently use every new sign.",
					"And there's more! Exclusive 'Signing Like a Pro' sections give you insider tips and tricks you won’t find in the online course, helping you sharpen your skills and elevate your fluency to the next level.",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/level1b/level-1b-instruction.png",
					alt: "Level 1B workbook pages with grammar instruction",
					width: 800,
					height: 600,
				},
				title: "Reinforce Your Learning",
				paragraphs: [
					"Unlock the key to mastering ASL with clear, engaging grammar lessons that make complex concepts easy to grasp. Our workbook is packed with fun, sentence-building exercises that directly connect to your video lessons, giving you the perfect practice for applying what you’ve learned.",
					"Plus, you’ll sharpen your ability to ask and answer questions—an essential skill for confident, real-world conversations. With every page, you’ll feel more fluent and ready to sign like a pro!",
					"Designed to elevate your signing abilities, this workbook is your step-by-step guide to ASL success!",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/level1b/level-1b-workbook-open.png",
					alt: "Interior of Building on the Basics Course Workbook",
					width: 800,
					height: 524,
				},

				sampleUrl: "/files/SignLanguage101_Level_1B_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/level1b/level-1b-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/SignLanguage101_Level_1B_Sample_Chapter.pdf",
	},
	{
		id: "12",
		handle: "elevating-your-signing-skills-course-workbook",
		title: "Elevating your Signing Skills - Course Workbook",
		author: "Sign Language 101",
		metaDescription:
			"Take your ASL to the next level with our comprehensive workbook. Practice advanced grammar, expand vocabulary, and deepen your understanding of Deaf culture.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 135,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "1 pound",
		dimensions: "8.5 x 11 inches",
		images: [
			{
				url: "/img/book/elevating-your-signing-skills-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Elevating your Signing Skills Course Workbook",
			},
			{
				id: 3,
				url: "/img/book/level-2-interior-2.png",
				alt: "Photo of interior of workbook",
				featuredImage: false,
				width: "600",
				height: "821",
			},
		],
		price: { amount: "22.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "71 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "1 pound" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		relatedProducts: [
			{
				id: 1,
				name: "ASL Level 2A - Online Course",
				url: "/courses/level-2a-online-asl-course",
				imageUrl: "/img/course/level-2-mock.jpg",
				imageAlt: "ASL Level 2A - Online Course image",
			},
			{
				id: 1,
				name: "ASL Level 2B - Online Course",
				url: "/courses/level-2b-online-asl-course",
				imageUrl: "/img/course/level-2-mock.jpg",
				imageAlt: "ASL Level 2B - Online Course image",
			},
			{
				id: 2,
				name: "Complete Online ASL Online Course",
				url: "/courses/complete-course-bundle",
				imageUrl: "/img/course/complete/complete-mock.png",
				imageAlt: "Complete Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/level2a/level-2a-spiral-cover.png",
					alt: "Elevating your Signing Skills Course Workbook",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/level2a/browser-book-mockup-level-2a.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"After each lesson, dive into engaging, interactive exercises that reinforce the key concepts you’ve just learned. These activities are designed to turn your knowledge into confident signing skills.",
					},
					{
						title: "📘 Hands-On Learning: ",
						content:
							"The workbook offers a variety of practice exercises that align directly with your course material, helping you master both vocabulary and grammar as you go.",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/level2a/level-2a-instruction.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Expand Your Vocabulary & Master Numbers",
				paragraphs: [
					"Take your ASL to the next level with easy-to-reference vocabulary lists that align with the signs and numbers you’ll master in the course. Each list is paired with interactive practice activities, ensuring you take the time to learn, remember, and confidently use every new sign.",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/level2a/level-2a-activities.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Reinforce Your Learning with Additional Activities",
				paragraphs: [
					"Strengthen your ASL receptive skills with workbook activities that directly align with the fingerspelling videos in your course. As you watch the course videos, you’ll be able to write down the words you see, helping you build speed and accuracy in recognizing and understanding ASL fingerspelling.",
					"But it doesn’t stop there! The workbook also includes dedicated pages with lists of words for you to practice fingerspelling on your own. These exercises help reinforce your skills, ensuring you become quicker and more confident in reading and producing fingerspelling in real-life conversations.",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/level2a/level-2a-workbook-open.png",
					alt: "Interior of Elevating your Signing Skills Course Workbook",
					width: 800,
					height: 524,
				},
				sampleUrl: "/files/SignLanguage101_Level_2A_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/level2a/level-2a-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/SignLanguage101_Level_2A_Sample_Chapter.pdf",
	},
	{
		id: "13",
		handle: "refining-your-asl-skills-course-workbook",
		title: "Refining your ASL Skills - Course Workbook",
		author: "Sign Language 101",
		metaDescription:
			"Perfect your ASL skills with our advanced workbook. Features comprehensive exercises for vocabulary building, grammar practice, and cultural understanding.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 135,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "1 pound",
		dimensions: "8.5 x 11 inches",
		images: [
			{
				url: "/img/book/refining-your-asl-skills-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Refining your ASL Skills Course Workbook",
			},
			{
				id: 3,
				url: "/img/book/level-2-interior-2.png",
				alt: "Photo of interior of workbook",
				featuredImage: false,
				width: "600",
				height: "821",
			},
		],
		price: { amount: "22.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "69 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "1 pound" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		relatedProducts: [
			{
				id: 1,
				name: "ASL Level 2A - Online Course",
				url: "/courses/level-2a-online-asl-course",
				imageUrl: "/img/course/level-2-mock.jpg",
				imageAlt: "ASL Level 2A - Online Course image",
			},
			{
				id: 1,
				name: "ASL Level 2B - Online Course",
				url: "/courses/level-2b-online-asl-course",
				imageUrl: "/img/course/level-2-mock.jpg",
				imageAlt: "ASL Level 2B - Online Course image",
			},
			{
				id: 2,
				name: "Complete Online ASL Online Course",
				url: "/courses/complete-course-bundle",
				imageUrl: "/img/course/complete/complete-mock.png",
				imageAlt: "Complete Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/level2b/level-2b-spiral-cover.png",
					alt: "Refining your ASL Skills Course Workbook",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/level2b/browser-book-mockup-level-2b.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"After each lesson, dive into engaging, interactive exercises that reinforce the key concepts you’ve just learned. These activities are designed to turn your knowledge into confident signing skills.",
					},
					{
						title: "📘 Hands-On Learning: ",
						content:
							"The workbook offers a variety of practice exercises that align directly with your course material, helping you master both vocabulary and grammar as you go.",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/level2b/level-2b-instruction.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Expand Your Vocabulary & Master Numbers",
				paragraphs: [
					"Take your ASL to the next level with easy-to-reference vocabulary lists that align with the signs and numbers you’ll master in the course. Each list is paired with interactive practice activities, ensuring you take the time to learn, remember, and confidently use every new sign.",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/level2b/level-2b-activities.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Reinforce Your Learning with Additional Activities",
				paragraphs: [
					"Strengthen your ASL receptive skills with workbook activities that directly align with the fingerspelling videos in your course. As you watch the course videos, you’ll be able to write down the words you see, helping you build speed and accuracy in recognizing and understanding ASL fingerspelling.",
					"But it doesn’t stop there! The workbook also includes dedicated pages with lists of words for you to practice fingerspelling on your own. These exercises help reinforce your skills, ensuring you become quicker and more confident in reading and producing fingerspelling in real-life conversations.",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/level2b/level-2b-workbook-open.png",
					alt: "Interior of Elevating your Signing Skills Course Workbook",
					width: 800,
					height: 524,
				},
				sampleUrl: "/files/SignLanguage101_Level_2B_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/level2b/level-2b-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/SignLanguage101_Level_2B_Sample_Chapter.pdf",
	},
	{
		id: "14",
		handle: "sign-language-101-for-kids-level-1ab-course-workbook",
		title: "Sign Language 101 for Kids Course Workbook",
		subtitle: "Levels 1A/1B",
		author: "Sign Language 101",
		metaDescription:
			"Support young learners with our ASL Level 1A & 1B workbooks. Packed with activities to enhance vocabulary, numbers, and grammar skills while introducing Deaf culture.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 98,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "1 pound",
		dimensions: "8.5 x 11 inches",
		audience: "kids",
		images: [
			{
				url: "/img/book/kids-course-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "ASL Level 2 Course Workbook",
			},
			{
				url: "/img/book/workbook1.png",
				width: 600,
				height: 689,
				featuredImage: false,
				alt: "spiral bound workbook on table",
			},
			{
				id: 2,
				url: "/img/book/student3.png",
				alt: "Student using the printed course workbook",
				featuredImage: false,
				width: "600",
				height: "800",
			},
		],
		price: { amount: "22.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "120 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "1 pound" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		disclaimer:
			"Note: A digital PDF version of the workbook is included for free with each course. We think our printed version makes your child's experience better!",
		relatedProducts: [
			{
				id: 1,
				name: "Sign Language 101 for Kids - Level 1A Online Course",
				url: "/courses/kids-asl-course-level-1a",
				imageUrl: "/img/home/multi_mockup3.png",
				imageAlt: "Sign Language 101 for Kids Level 1A - Online Course image",
			},
			{
				id: 1,
				name: "Sign Language 101 for Kids - Level 1B Online Course",
				url: "/courses/kids-asl-course-level-1b",
				imageUrl: "/img/home/multi_mockup3.png",
				imageAlt: "Sign Language 101 for Kids Level 1B - Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/kids/kids-spiral-cover.png",
					alt: "Getting Started with ASL Course Workbook",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/kids/browser-book-mockup-kids.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"After each lesson, your child will dive into playful, interactive activities that reinforce the key concepts they’ve just learned. These exercises make practicing ASL fun and help build their confidence in signing.",
					},
					{
						title: "📘 Pro Tips for Success:",
						content:
							"Throughout the workbook, they'll discover expert tips tailored just for kids! It’s like having a personal tutor guiding them, helping them master signing step by step.",
					},
					{
						title: "🎨 Made for Kids!:",
						content:
							"Bright, colorful pages and engaging activities are designed specifically to make learning ASL exciting for children. From fun games to creative exercises, this workbook is crafted to keep kids engaged and eager to learn!",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/kids/kids-activities.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Boost Your Child’s Vocabulary & Master Numbers",
				paragraphs: [
					"our child will love the easy-to-follow vocabulary lists that go hand-in-hand with the ASL signs and numbers they learn in the course. Fun practice activities ensure they truly learn and remember each new sign, building a strong foundation for their ASL skills.",
					"Throughout the workbook, they’ll find exclusive Language Tips packed with special insights and tricks made just for kids. These extra insights will help them refine their signing and take their confidence to the next level!",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/kids/kids-activities.png",
					alt: "ASL Course Workbooks",
					width: 676,
					height: 517,
				},
				title: "Reinforce Your Child’s Learning",
				paragraphs: [
					"Our workbook makes learning ASL grammar fun and easy with clear, kid-friendly explanations that connect directly to their video lessons. Your child will enjoy practicing sentence-building exercises that help them apply what they’ve just learned in a hands-on way.",
					"They’ll also practice asking and answering questions—an important step for improving their conversational skills. With each page, your child will gain more confidence, fluency, and be ready to sign accurately in everyday situations.",
					"This workbook is designed to help your child truly master ASL and build strong signing abilities with every lesson!",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/kids/kids-course-workbook-open.png",
					alt: "Interior of Sign Language 101 for Kids Course Workbook",
					width: 800,
					height: 524,
				},
				sampleUrl: "/files/SignLanguage101_Level_1A_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/kids/kids-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/Sign-Language-101-For-Kids-Workbook-Sample.pdf",
	},
	{
		id: "15",
		handle: "asl-essentials-workbook-bundle",
		title: "ASL Essentials Course Workbook Bundle",
		author: "Sign Language 101",
		metaDescription:
			"Support young ASL learners with comprehensive Level 1A & 1B workbooks. Fun activities for vocabulary, numbers, and basic grammar skills.",
		publisher: "Sign Language 101",
		language: "English",
		format: "Spiral bound",
		pages: 222,
		isbn: "0593241509",
		shopifyProduct: true,
		weight: "2 pounds",
		dimensions: "8.5 x 11 inches",
		images: [
			{
				url: "/img/book/getting-started-with-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Getting Started with ASL Course Workbook",
			},
			{
				url: "/img/book/building-on-the-basics-of-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: false,
				alt: "Building on the Basics of ASL Course Workbook",
			},
			{
				id: 3,
				url: "/img/book/level-1-interior.png",
				alt: "Photo of interior of workbook",
				featuredImage: false,
				width: "600",
				height: "821",
			},
		],
		price: { amount: "44.99", currencyCode: "USD" },
		details: [
			{ label: "Format", value: "Spiral bound" },
			{ label: "Pages", value: "222 pages" },
			{ label: "Language", value: "English" },
			{ label: "Weight", value: "2 pounds" },
			{ label: "Dimensions", value: "8.5 x 11 inches" },
			{ label: "Publisher", value: "Sign Language 101" },
		],
		policies: [
			{
				name: "Delivery Options",
				description: "Shipping to addresses in Canada and the United States",
			},
		],
		relatedProducts: [
			{
				id: 1,
				name: "ASL Level 1A - Online Course",
				url: "/courses/level-1a-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 1,
				name: "ASL Level 1B - Online Course",
				url: "/courses/level-1b-online-asl-course",
				imageUrl: "/img/course/level-1-mock.jpg",
				imageAlt: "ASL Level 1B - Online Course image",
			},
			{
				id: 2,
				name: "Complete Online ASL Online Course",
				url: "/courses/complete-course-bundle",
				imageUrl: "/img/course/complete/complete-mock.png",
				imageAlt: "ASL Level 2 - Online Course image",
			},
		],
		sections: {
			header: {
				image: {
					url: "/img/book/level1a/level-1a-1b-spiral-cover.png",
					alt: "Level 1A & Level 1B Workbooks",
					width: 500,
					height: 583,
				},
			},
			howItWorks: {
				image: {
					url: "/img/book/level1a/browser-book-mockup-level-1a.png",
					alt: "Course Workbook page with corresponding video lesson",
					width: 600,
					height: 401,
				},
				items: [
					{
						title: "✨ Practice Makes Perfect:",
						content:
							"Dive into fun, interactive exercises after each lesson that lock in key concepts and turn them into confident signing skills.",
					},
					{
						title: "📘 Pro Tips for Success:",
						content:
							"Get insider advice throughout the workbook that feels like having your own ASL tutor. You’ll be signing like a pro in no time!",
					},
					{
						title: "🎯 Extended Learning:",
						content:
							"At the end of each chapter, tackle enrichment activities that stretch your understanding and make mastering ASL both fun and effective.",
					},
				],
			},
			highlightOne: {
				image: {
					url: "/img/book/level1a/level-1a-activities.png",
					alt: "ASL workbook interior pages with vocabulary lists",
					width: 676,
					height: 517,
				},
				title: "Boost Your Vocabulary & Nail Those Numbers",
				paragraphs: [
					"Stay on top of your ASL journey with easy-to-reference vocabulary lists for all the signs and numbers you’ll master in the course. Our workbook is filled with fun practice activities to help you truly learn, remember, and confidently use every new sign.",
					"And there's more! Exclusive 'Signing Like a Pro' sections give you insider tips and tricks you won’t find in the online course, helping you sharpen your skills and elevate your fluency to the next level.",
				],
			},
			highlightTwo: {
				image: {
					url: "/img/book/level1a/level-1a-instruction.png",
					alt: "Level 1A workbook pages with grammar instruction",
					width: 676,
					height: 517,
				},
				title: "Reinforce Your Learning",
				paragraphs: [
					"Unlock the key to mastering ASL with clear, engaging grammar lessons that make complex concepts easy to grasp. Our workbook is packed with fun, sentence-building exercises that directly connect to your video lessons, giving you the perfect practice for applying what you’ve learned.",
					"Plus, you’ll sharpen your ability to ask and answer questions—an essential skill for confident, real-world conversations. With every page, you’ll feel more fluent and ready to sign like a pro!",
					"Designed to elevate your signing abilities, this workbook is your step-by-step guide to ASL success!",
				],
			},
			bookPreview: {
				image: {
					url: "/img/book/level1a/level-1a-workbook-open.png",
					alt: "Interior of Getting Started with ASL Course Workbook",
					width: 800,
					height: 524,
				},
				sampleUrl: "/files/SignLanguage101_Level_1A_Sample_Chapter.pdf",
			},
			cta: {
				image: {
					url: "/img/book/level1a/level-1a-pages-right.png",
					alt: "ASL Course Workbook pages",
					width: 500,
					height: 382,
				},
			},
		},
		sampleUrl: "/files/SignLanguage101_Level_1A_Sample_Chapter.pdf",
	},
];
