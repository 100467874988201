import React from "react";
import Nav from "@/components/common/Nav";
import Footer from "@/components/common/FooterExpanded";
import { Button } from "@/components/common/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false, error: null };
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI

		return { hasError: true, error };
	}
	componentDidCatch(error, info) {
		// You can use your own error logging service here
		console.error("Caught error:", error, info);
	}
	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="bg-white">
					<Nav />
					<div className=" pb-14 md:pb-24 pt-24">
						<div className="px-4 flex justify-center flex-col mb-4 text-center">
							<div className="flex w-full justify-center">
								<ExclamationTriangleIcon className="h-12 w-12 text-orange-300" />
							</div>
							<h1 className="font font-bold font-heading text-gray-900 text-4xl md:text-5xl mt-4">
								Sorry, something went wrong.
							</h1>
						</div>
						<div className="px-4 flex justify-center text-center ">
							<p className="mt-4 text-base text-gray-600 md:w-1/2">
								We&apos;ve been notified of the issue. You can try to reload the
								page or return to the home page.
							</p>
						</div>
						<div className="px-4 gap-x-10 mt-12 flex-col md:flex-row gap-y-6 flex justify-center text-center ">
							<Button
								variant="solid"
								color="blue"
								onClick={() => this.setState({ hasError: false })}
								className="lg:w-48 order-1 lg:order-2"
							>
								Reload Page
							</Button>
							<Button
								href="/"
								variant="outline"
								color="slate"
								className="lg:w-48 order-2 lg:order-1"
							>
								Return Home
							</Button>
						</div>
					</div>

					<Footer />
				</div>
			);
		}

		// Return children components in case of no error

		return this.props.children;
	}
}

export default ErrorBoundary;
